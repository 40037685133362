import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';




 function MinistryCard(props) {
    //console.log(props);
    const navigate = useNavigate()
  return (
    <Card  
    sx={{ p:0, m:2, width: 300 ,maxWidth: 335 ,minHeight: 400 , maxHeight: 450, 
    backgroundColor:'#008ca8',
     boxShadow: '0 2px 14px 0 rgb(32 40 45 / 15%)', ':hover': {
                                boxShadow : '0 2px 14px 0 rgb(32 40 45 / 100%)' ,
                            }}}
    >
    <CardActionArea  onClick={() => {
                    navigate(props.details.path)
                    }}>
    <CardMedia
      component="img"
      alt={props.mtext}
      height = '155'
      image={props.details.pic}
    />
    <CardContent>
      <Typography gutterBottom variant="h6" component="div" color="white"> 
        {props.details.mtext}
      </Typography>
      <Typography variant="body1" color="white" align="justify">
        {props.details.desc}
      </Typography>
    </CardContent>
    <CardActions>
      
      <Button variant="outlined" sx={{ bgcolor: '#fff', fontWeight:'Bold', color:'#1e8a84', borderBlockColor:'#1e8a84'}} size="small" onClick={() => {
                        navigate(props.details.path)
                      }}>Learn More</Button>
    </CardActions>
    </CardActionArea>
  </Card>
  );
}

export default MinistryCard;