import React from 'react'
import { Grid } from '@mui/material';
import { pg_nepal, np_promo } from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PageSubTitle from "../PageSubTitle";
import BulletPoints from '../BulletPoints';
import BarGraph from '../BarGraph';
import BarGraphPol from '../BarGraphPol';
import { PgnepalBulletPoints } from "/react/mfa/src/dataset/nepal";
import { nepalBarPoldata, nepalBarGraphData, nepalImgList } from "/react/mfa/src/dataset/nepal";
import Link from '@mui/material/Link';
import ImageListApp from '../ImageListApp'


const PgNepal = () => {
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF'}}>
          <img src={pg_nepal} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='About Nepal'/>
                  <Typography variant="body1" color="" align="justify">
                  Eight of the world’s ten highest mountains can be found in Nepal’s Himalayan Mountains.  Most notably, Mount Everest is the highest point above sea level in the world at 8850 meters (29,035 ft.).  From its mountainous northern border with Tibet (China), Nepal’s topography falls to near sea level at its southern border with India on the Ganges River plain.  Most of the over 36 ethnic groups in this South Asian country live in the central hill area and in the flatter and arid lowlands in the south. Nepal’s Sherpa guides of the Himalayas and the Gurkha military regiments are peoples recognized internationally for their skill and bravery.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  Isolation and a long-ruling monarchy have contributed to Nepal being one of the poorest and most underdeveloped countries in the world. It is estimated that 25% to 40% of the Nepalese people live below the poverty line.  Most of Nepal’s people are subsistence farmers where geographic, environmental, and political factors have impacted the growth of agriculture as well as industry.  Nepal ended its long line of monarchies by becoming a republic in 2008, yet political instability, civil strife, and labor problems continue.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  As the world’s last remaining Hindu kingdom, over 80% of the Nepalese people are Hindu.  Buddhists comprise around ten percent of the population. Religious freedom is legal under Nepalese law, but restrictions are imposed on non-Hindu groups. Christian believers are at risk for fines and even imprisonment for proselytizing.  Though the percentage of Christians in Nepal is small, the number of believers is growing.  Each of the 75 districts has a church plant and believers can be found in almost every people and caste group.
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by Religion'/>
                  <BarGraphPol {...nepalBarPoldata}/>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/nepal/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by People Group'/>
                  <Typography variant="body1" color="" align="justify">
                  <BarGraph {...nepalBarGraphData}/>
                  </Typography>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/nepal/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>
          
            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='How can you pray for Nepal ?'/>
                   <BulletPoints details={PgnepalBulletPoints} />
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='MFA Ministries in Nepal'/>
                  <Typography variant="body1" color="" align="justify">
                    <ImageListApp imglist={nepalImgList}/>
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Will you support a Child ?'/>
                  <Typography variant="body1" color="" align="justify">
                    <Box
                      component="img"
                      sx={{
                          maxHeight: { xs: 233, md:400 },
                            maxWidth: { xs: 350, md:500  },
                          }}
                      alt="Will you support a Child ?"
                      src={np_promo}
                    />
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>
          
            <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}


export default PgNepal;