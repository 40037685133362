import React from 'react'
import { Grid } from '@mui/material';
import { connectWithStateTeam } from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { icon_nsw,
    icon_vic,
    icon_sa,
    icon_wa } from "/react/mfa/src/img";
import PageSubTitle from "../PageSubTitle";
import BulletPoints from "../BulletPoints";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ConnectWithStateTeam = () => {
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF'}}>
          <img src={connectWithStateTeam} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name="Serve the Lord through your state's missions team"/>
                <Typography variant="body1" color="" align="justify">
                Missions Fellowship Australia has always been 
dedicated to prayer and seeking God, both individually and collectively, at ministry 
locations, across nations, and at various other levels. One of MFA’s key state-level prayer 
initiatives is the monthly gathering known as the Missions Prayer Fellowship. <br></br><br></br>This 
gathering focuses on prayer points provided by overseas mission partners, addressing day-to-day ministry challenges, needs, and spiritual support within specific ministry streams. 
We also connect with missionaries and mission partners via Zoom to share testimonies 
and experiences.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Be a Sponsor'/>
                <Typography variant="body1" color="" align="justify">
                Support one of MFA's sponsored mission projects in the Southeast Asia region.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Be a Prayer Partner'/>
                <Typography variant="body1" color="" align="justify">
                We receive prayer points from our overseas mission partners and share 
specific prayer needs for their missionaries, ministries, and missions, encouraging others 
to lift them up in prayer.

                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Partnership'/>
                <Typography variant="body1" color="" align="justify">
                Churches and mission organizations can partner with MFA to provide 
financial support to overseas missionaries. This support helps train the missionaries to not 
only empower local communities but also share the gospel with people who may 
otherwise not have the opportunity to hear the good news.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Publications and Media Support'/>
                <Typography variant="body1" color="" align="justify">
                Support the release of the monthly Prayer Plus by helping put together 
print and digital publications, ensuring that all sponsors are well-informed about global 
missions and MFA’s national initiatives.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Events'/>
                <Typography variant="body1" color="" align="justify">
                  MFA conducts missions events on following areas,
                  <br></br>
                  <BulletPoints details={connectStatesTeamBulletPoints}/>
                  
                  
                  
                                  </Typography>
              </Container>
          </Box>

            <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const connectStatesTeamBulletPoints =[
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Annual missions connect',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'National missions confrence',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Monthly fasting prayers',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Quarterly all night paryers',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Yearly sponsors meet',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Annual general body members meeting',
  },  
];

const statePrayer = [
    {
      img: icon_nsw,
      title: 'New South Wales',
      author: '@sydney',
      path: '',
      content: 'The 2nd Saturday of each month at 6:30pm, or on whatever day close to that works better for local state members and sponsors availability.',
    },
    {
      img: icon_vic,
      title: 'Victoria',
      author: '@melbourne', 
      path: '',
      content: 'The 4th Saturday of each month at 6:30pm, or on whatever day close to that works better for local state members and sponsors availability in Baptist Church, 25/27 James Street, Dandenong, VIC-3175.',
    },
    {
      img: icon_sa,
      title: 'South Australia',
      author: '@adelaide',     
      path: '',
      content: 'The 1st Saturday of each month at 5:00pm, or on whatever day close to that works better for local state members and sponsors availability in 11 Broad Street, Queens Town, Adelaide, SA-5014. Please call 0434 508 345 for more info.',
    },
    {
      img: icon_wa,
      title: 'Western Australia',
      author: '@perth',   
      path: '',
      content: 'The 2nd Friday of each month at 7:00pm, or on whatever day close to that works better for local state members and sponsors availability,',   
    },
   
  ];

export default ConnectWithStateTeam