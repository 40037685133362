import React from 'react'
import { lifeTransformation, lt_contactStateMissions,
   lt_chooseYourAdultProject, lt_FollowConnectPray,
   lt_country_cambodia, 
   lt_country_srilanka,lt_country_myanmar} from "C:/react/mfa/src/img";
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import {PostItlabel} from "C:/react/mfa/src/components";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import VTSteps from "../VTSteps";
import ContactForm from "../ContactForm";
import PageSubTitle from "../PageSubTitle";
import BulletPoints from "../BulletPoints";
import MFADonateMenu from "../MFADonateMenu";

const LifeTransformation = () => {

  const [SFormLT, setSFormLT] = useState(false);

  const ShFormLT = () => {
    setSFormLT(!SFormLT);
  }

  return (
    <>
    <Grid sx={{ bgcolor: '#FFF'}}>
      <Grid>
        <Box sx={{ bgcolor: '#FFF'}}>
        <img src={lifeTransformation} alt="MFA" className='mfahomebanner'/>
        </Box>
      </Grid>
      <Grid container>
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Life Transformation Ministry (LT)'/>
              <Typography variant="body1" color="" align="justify">
                <b>Empower young adults (aged 16-21) to break free from poverty by coming alongside a family and providing them with skills to break the cycle of poverty.</b></Typography>
              <br></br>
              <Typography variant="body1" color="" align="justify">
              Sponsorship enables counselling, emergency medical care and further education and skills-training. MFA provides job oriented industrial training such as in tailoring, computer technology and business skills. In a culturally sensitive way, young adults and their families are given an opportunity to hear the gospel message.  
              </Typography>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='How to Sponsor a Young Adult ?'/>
              <br></br>
              <VTSteps details={steps}/>
            </Container>
        </Box>
        
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">  
          <PageSubTitle name='Sowing Your Seeds'/>
            <Box  sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {CPcountryList.map((country) => (
                    <Grid item  xs={4}>
                      <PostItlabel details={country}/>
                    </Grid>
                ))}
              </Grid>
            </Box>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Sponsor a Young Adult'/>
              <BulletPoints details={LTynAdultBulletPoints}/>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Be a Prayer Partner'/>
                <Typography variant="body1" color="" align="justify">
                  MFA has always been committed to prayer. You can join one of our <b>“Monthly Missions Prayer Fellowship”</b> in your local state and be part of the prayer team. You will also receive monthly MFA prayer newsletter to pray with your family and other believers.
                </Typography>
              </Container>
          </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Online Donation'/>
                <Typography variant="body1" color="" align="justify">
                  Click on the donate button to make an online donation today.
                <Stack direction="row" spacing={2}>
                  <MFADonateMenu />
                </Stack>
                </Typography>
              </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='What Next?'/>
              <BulletPoints details={LTnextBulletPoints}/>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Further Questions ?'/>
              <Typography variant="body1" color="" align="justify">
              If you would like to know more, we encourage you to meet one of our mission team member face to face or feel free to send in your questions to us via email.
              </Typography>
              <br></br>
              
            </Container>
        </Box>

        <Grid container spacing={0} sx={{  height: 50}}></Grid>
      </Grid>
    </Grid>
  </>
  )
}

const CPcountryList = [ 
  {
      mtext:'Myanmar',
      path: '/PageMyanmar',
      img: lt_country_myanmar,
  },
  {    
      mtext:'Sri Lanka',
      path: '/PgSriLanka',
      img: lt_country_srilanka,
  },
  {    
      mtext:'Cambodia',
      path: '/PgCambodia',
      img:lt_country_cambodia, 
  }
];

const steps = [
  {
    id:0,
    name:'Contact State Missions Team',
    img: lt_contactStateMissions,
  },
  {
    id:1,
    name:'Choose Your Adult Project',
    img: lt_chooseYourAdultProject, 
  },
  {
    id:2,
    name: 'Follow Connect & Pray',
    img: lt_FollowConnectPray,
  }
];

const LTynAdultBulletPoints =[
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'You can partner with us and sponsor a young adult project which will support 12 young adults for a period of 12 months. The cost of the project varies from $300 to $500/month based on project that we run. We currently have young adult projects in Cambodia, Myanmar, Nepal and Sri Lanka.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: '100% of your donation will go to the project and is not used for MFA administration. Based on your giving, you can either be an individual sponsor or part of a group sponsorship where MFA will pool a number of sponsors together to support a project. Your donation in Australia is tax deductible.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: ' MFA encourages automatic recurring monthly payment by Electronic Fund Transfer (EFT).',
  },
];

const LTnextBulletPoints =[
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will be given a registration ID & dedicated account number to transfer funds.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will receive a copy of your project profile through your registered email.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will be assigned a dedicated Young Adult Project as soon as a donation is made through online or direct debit at your side.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Connect one of our State Missions Prayer Fellowship, your prayers are important for the success of young adult progress.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will receive regular reports about your supported ministry that you are sponsoring.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will be encouraged to participate in mission trips, MFA will facilitate your visit.',
  },
 
];

export default LifeTransformation;