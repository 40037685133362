import React from 'react';
import { missionsTeamWa } from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

const AboutTile = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#efefef' : '#efefef',
    ...theme.typography.h4,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: 'black',
  }));  

const MissionsTeamWa = () => {
  return (
    <>
    <Grid sx={{ bgcolor: '#efefef'}}>
      <Grid>
        <Box sx={{ bgcolor: '#FFF' }}>
        <img src={missionsTeamWa} alt="MFA" className='mfahomebanner'/>
        </Box>
      </Grid>
      <Grid container>
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">
          <AboutTile>
            <b>How can you engage with a local missions team ?</b>
          </AboutTile><br></br>
          <Typography variant="h6" color="" align="justify">
            By Sponsor a child, Sponsor a native missionary, Sponsor a young adult, Support Benovent Project.
          </Typography>
          </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">
            <AboutTile>
                <b>Connect through Prayer</b>
            </AboutTile><br></br>
              <Typography variant="h6" color="" align="justify">
                Missions Fellowship Australia has always been committed to prayer and seeking God. We do this as individuals, as ministry locations, as countries and at many other levels. One of MFA’s State level prayer initiatives is a monthly prayer gathering called “<b>Missions Prayer Fellowship</b>”.
                <br></br><br></br>
                This prayer point from Overseas missions partners on a monthly basis in respective ministry streamlines focuses on day to day ministry challenges / needs/ Spiritual Supports. We also engage missionaries and mission’s partners through the ZOOM channel to share testimonies.  
              </Typography>
          </Container>
        </Box>
        
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
              <AboutTile>
              <b>Venue</b>
              </AboutTile><br></br>
              <Typography variant="h6" color="" align="justify">
                The 2nd Friday of each month at 7:00pm, or on whatever day close to that works better for local state members and sponsors availability
              </Typography>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
              <AboutTile>
                <b>Annual Missions Connect program</b>
              </AboutTile><br></br>
              <Typography variant="h6" color="" align="justify">
                MFA Missions Connect are a significant part of our vision to "Reach The Unreached" for a world that knows Jesus as per Matt 28:19
                <br></br><br></br>
                You have an opportunity to hear what the Bible says about mission and evangelical  ministry. You have a time to hear directly from missionaries / partner mission's organization representative's about what God is doing around MFA sponsored countries and how He is building His church. So whether you tune in online or come in person, you don’t want to miss out.
                <br></br><br></br>
                Keep informed by regularly attending your State Monthly Mission Prayer Fellowship Meetings or by signing up for our regular communications from your local State Missions Team.
              </Typography>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
              <AboutTile>
                <b>Contact Your local State Missions Facilitator</b>
              </AboutTile><br></br>
              <Typography variant="h6" color="" align="justify">
                Email: wa@mfa.org.au 
                <br></br><br></br>
                If you would like to know more, we encourage you to investigate even further by setting up a time to come and meet with us, or feel free to send in your questions to us via email.
                <br></br><br></br>
                Contact Form: work in progress
              </Typography>
            </Container>
        </Box>
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
      </Grid>
    </Grid>
  </>
  )
}

export default MissionsTeamWa