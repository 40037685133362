import React from 'react'
import { Grid } from '@mui/material';
import { connectWithUs } from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { gi_stateMissionsTeam, gi_missionsTour, 
  gi_Giving, gi_Prayer, } from "C:/react/mfa/src/img";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import PageSubTitle from "../PageSubTitle";
import { useNavigate } from 'react-router-dom';

const ConnectWithUs = () => {
  const navigate = useNavigate()
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF'}}>
          <img src={connectWithUs} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Connect with Us'/>
                <Typography variant="body1" color="" align="justify">
                    You can connect with MFA in many ways as per God’s calling and purpose. 
                   
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Container maxWidth="md">
          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={1}>
                {connectTiles.map((tiles) => (
                <Card sx={{ m:2, maxWidth: 345 ,bgcolor: '#008ca8'}}  className=''>
                <CardActionArea  onClick={() => {
                    navigate(tiles.path)
                    }}>
                    <CardMedia
                    component="img"
                    alt={tiles.title}
                    height="185"
                    image={tiles.img}
                    />
                    <CardContent sx={{ bgcolor: '#008ca8'}}>
                    
                    <Typography variant="h5" color="white">
                    {tiles.title}
                    </Typography>
                    <Typography variant="body2" color="white">
                    {tiles.content}
                    </Typography>
                    </CardContent>
                    
                    </CardActionArea>
                </Card>
              ))}
                </Grid>
            </Grid>
        </Grid>
        </Container> 
              
              
             
          
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const connectTiles = [
    {
        img: gi_Prayer,
        title: 'Pray with Us',
        path: '/ConnectInPrayer',
        content: 'Join us in prayer as we ask for God’s will to be done and we listen for His voice. Join MFA’s monthly Prayer Plus Electronic Newsletter and partner with us in prayer.',
    },
    {
      img: gi_Giving,
      title: 'Connect through Giving ',
      path: '/ConnectThroughGiving',
      content: 'Learn how to partner with MFA financially. Help MFA share the good news of Jesus and care for people in Southeast Asia.',
    },    
    {
        img: gi_missionsTour,
        title: 'Go on a Missions Trip',
        path: '/MissionsTrip',
        content: 'Whether your interest is construction, medicine, sports, arts, compassion, evangelism, or all of the above, MFA has a mission trip for you. There are trips for families and children, programs for churches, and you can also go with your youth group or adult group.',
    },
    {
        img: gi_stateMissionsTeam,
        title: 'Join a MFA State Team',
        path: '/ConnectWithStateTeam',
        content: 'You can join MFA to serve the Lord for a couple of years or for life.  Expand and strengthen the state missions team and leadership, while pioneering new ministries and enhancing established ones',
    },
   
  ];

export default ConnectWithUs