import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import MLink from '@mui/material/Link';
import "./MfaNavBarNew.css"
import { MFALogo } from "../img";
import { Grid , Box} from '@mui/material';
import { MenuItems} from './InputData';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ChurchTwoToneIcon from '@mui/icons-material/ChurchTwoTone';
import HolidayVillageTwoToneIcon from '@mui/icons-material/HolidayVillageTwoTone';
import ManTwoToneIcon from '@mui/icons-material/ManTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';








const donateList =[
    {
        listId: 'listChurchPlanting',
        listPath: '',
        listTitle: 'Church Planting',
        listText:'Church Planting',
        listIcon: <ChurchTwoToneIcon sx={{color:'#008ca8'}}/>,
        submenu : [
            {
                sublistId: 'sublistChurchPlantingIndia',
                sublistPath: 'https://give.tithe.ly/?formId=1af2f060-adbf-42e5-afaa-17c6aedae09c&locationId=da432446-62d9-4f60-b04e-92e13843d4ac&fundId=e897008a-c9bd-4f2f-b8f6-cfc1782b1a65',
                sublistTitle: 'India',
                sublistText:'Church Planting India'
            },
            {
                sublistId: 'sublistChurchPlantingNepal',
                sublistPath: 'https://give.tithe.ly/?formId=c29c425e-755c-4f4a-a66e-ef1ec782ec35&locationId=b6aca89c-b041-48da-8c82-8e6d6dcbd851&fundId=e1113c6e-6a31-48e8-9bac-41d52e60eb4e',
                sublistTitle: 'Nepal',
                sublistText:'Church Planting Nepal'
            },
            {
                sublistId: 'sublistChurchPlantingSriLanka',
                sublistPath: 'https://give.tithe.ly/?formId=2d71e73b-6412-4438-84fd-60008c86692d&locationId=16853d3f-f1a3-4a16-9196-049fc8061efc&fundId=2139db90-de58-4fa9-9e91-9d2267535822',
                sublistTitle: 'Sri Lanka',
                sublistText:'Church Planting Sri Lanka'
            }
        ]
    },   
    {
        listId: 'listVillageTransformation',
        listPath: '',
        listTitle: 'Village Transformation',
        listText:'Village Transformation',
        listIcon: <HolidayVillageTwoToneIcon sx={{color:'#008ca8'}}/>,
        submenu : [
            {
                sublistId: 'sublistVillageTransformationCambodia',
                sublistPath: 'https://give.tithe.ly/?formId=3515a9cf-04a6-4876-8c74-4a26f94af44c&locationId=681eb4f1-e20d-4037-8ba2-a8c0cabb1090&fundId=2386d2bd-87fd-4d39-997c-4a4791374fc8',
                sublistTitle: 'Cambodia',
                sublistText:'Church Planting Cambodia'
            },
            {
                sublistId: 'sublistVillageTransformationIndia',
                sublistPath: 'https://give.tithe.ly/?formId=3515a9cf-04a6-4876-8c74-4a26f94af44c&locationId=681eb4f1-e20d-4037-8ba2-a8c0cabb1090&fundId=de648ed3-41ef-41e3-ae83-4a14e3c36d2a',
                sublistTitle: 'India',
                sublistText:'Church Planting India'
            },
            {
                sublistId: 'sublistVillageTransformationMyanmar',
                sublistPath: 'https://give.tithe.ly/?formId=3515a9cf-04a6-4876-8c74-4a26f94af44c&locationId=681eb4f1-e20d-4037-8ba2-a8c0cabb1090&fundId=cb6ef1d5-df22-4f31-ae4a-9af9bba3c5e8',
                sublistTitle: 'Myanmar',
                sublistText:'Church Planting Myanmar'
            },
            {
                sublistId: 'sublistVillageTransformationNepal',
                sublistPath: 'https://give.tithe.ly/?formId=3515a9cf-04a6-4876-8c74-4a26f94af44c&locationId=681eb4f1-e20d-4037-8ba2-a8c0cabb1090&fundId=b79478bb-3c4e-4e60-89e4-cd9f176fafc5',
                sublistTitle: 'Nepal',
                sublistText:'Church Planting Nepal'
            },
            {
                sublistId: 'sublistVillageTransformationSriLanka',
                sublistPath: 'https://give.tithe.ly/?formId=3515a9cf-04a6-4876-8c74-4a26f94af44c&locationId=681eb4f1-e20d-4037-8ba2-a8c0cabb1090&fundId=f35d73cf-6923-4382-b402-0c7531fddde1',
                sublistTitle: 'Sri Lanka',
                sublistText:'Church Planting SriLanka'
            },
        ]
    },   
    {
        listId: 'listLifeTransformation',
        listPath: '',
        listTitle: 'Life Transformation',
        listText:'Life Transformation',
        listIcon: <ManTwoToneIcon sx={{color:'#008ca8'}}/>,
        submenu : [
            {
                sublistId: 'sublistLifeTransformationCambodia',
                sublistPath: 'https://give.tithe.ly/?formId=de3a7ec5-5075-42e8-8cc8-165f0e99e23a&locationId=05963ac5-d8de-45b4-a579-4645d83dae43&fundId=54ab7725-20f3-4ce1-9d88-1c862807e26a',
                sublistTitle: 'Cambodia',
                sublistText:'Life Transformation Cambodia'
            },
            {
                sublistId: 'sublistLifeTransformationMyanmar',
                sublistPath: 'https://give.tithe.ly/?formId=de3a7ec5-5075-42e8-8cc8-165f0e99e23a&locationId=05963ac5-d8de-45b4-a579-4645d83dae43&fundId=4ed4bfef-f527-42d2-8c47-10e896228610',
                sublistTitle: 'Myanmar',
                sublistText:'Life Transformation Myanmar'},
            {
                sublistId: 'sublistLifeTransformationSriLanka',
                sublistPath: 'https://give.tithe.ly/?formId=de3a7ec5-5075-42e8-8cc8-165f0e99e23a&locationId=05963ac5-d8de-45b4-a579-4645d83dae43&fundId=1e884f3f-0e68-4a1b-8b93-39e3f32fcc6e',
                sublistTitle: 'Sri Lanka',
                sublistText:'Life Transformation SriLanka'
            }
        ]
    }, 
    {
        listId: 'listMercyMinistry',
        listPath: '',
        listTitle: 'Mercy Ministry',
        listText:'Mercy Ministry',
        listIcon: <FavoriteTwoToneIcon sx={{color:'#008ca8'}}/>,
        submenu : [{
                sublistId: 'listMercyMinistry',
                sublistPath: 'https://give.tithe.ly/?formId=806ba947-5637-46b0-998c-0225e65b6c2c&locationId=c6d4ace3-4821-4250-92a2-d78debaff580&fundId=edfcc86f-dc23-4239-91c5-8b7015a28706',
                sublistTitle: 'Mercy Donations',
                sublistText:'Mercy Ministry Donations'
            }
          ]
    }, 
  ];
  

const MFADonateMenu = () => {
    const [dopen, setDopen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState("")
    const toggleDrawer = (newOpen) => () => {
        setDopen(newOpen);
      };
      const subhandleClick = index => {
        if (selectedIndex === index) {
          setSelectedIndex("")
        } else {
          setSelectedIndex(index)
        }
      }

      const DrawerList = (
        <Box sx={{ width: 350 }} role="presentation" key='donateHomepage'>
            <List
                
                aria-labelledby="nested-list-subheader"
                subheader={
                <ListSubheader component="div" id="nested-list-subheader"  
                sx={{color:'#008ca8', fontSize:'1.5rem',fontWeight:'bold'}} onClick={toggleDrawer(false)}>
                Donate to MFA <Button variant="outlined" sx={{p:0, float:'right', marginTop: '5px'}}><CloseTwoToneIcon/></Button>
                </ListSubheader>
                }
            ><Divider />
      {donateList.map((item, index) => {
        return (
          <><List>
            <ListItemButton
              key={index}
              
              onClick={() => {
                subhandleClick(index)
              }}
            >
              <ListItemIcon>
                {item.listIcon}
              </ListItemIcon>
              <ListItemText primary={item.listTitle} 
                primaryTypographyProps={{
                    
                    fontWeight: 'bold',
                    variant: 'body1',
                  }}
                sx={{color:'#008ca8', fontSize:'1rem'}} />
                {index === selectedIndex ? <ExpandLess  sx={{color:'red'}}/> : <ExpandMore  sx={{color:'red'}}/>}
            </ListItemButton>
            <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.submenu.map((sub, index) => {
                  return (
                    <ListItemButton key={sub.sublistId} component='a' href={sub.sublistPath} target="_blank">
                    <ListItemIcon> </ListItemIcon>
                      <ListItemText primary={sub.sublistTitle} sx={{color:'#008ca8', fontSize:'1rem',fontWeight:'bold'}} />
                    </ListItemButton>
                  )
                })}
              </List>
            </Collapse>
          </List><Divider></Divider></>
        )
      })}
    </List>
         <List sx={{ position: "absolute", bottom: "0" }}>
         <ListItemText primary='powered by tithely' ></ListItemText>
         </List> 
        </Box>
      )

  return (
    <><Button variant="outlined"  sx={ { borderRadius: 28,color:'white',backgroundColor: '#008ca8',
        fontWeight: 'bold', fontSize: '18px', borderColor: 'white',
        ':hover': {
            boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' ,
            color: '#008ca8', borderColor: '#008ca8'
        },}}
        endIcon={<VolunteerActivismIcon /> } onClick={toggleDrawer(true)}> Donate </Button>
        <Drawer anchor='right' open={dopen} onClose={toggleDrawer(false)}>
            {DrawerList}
        </Drawer></>
    
  )
}

export default MFADonateMenu