import {baptism, np_group1, np_group2, np_group3, np_cgroup1, np_cgroup2, np_cgroup3, np_gathering,} from '/react/mfa/src/img'

export const nepalBarPoldata =[
    {
      populationDataSet : [
        {
          Hindu: 81.2,
          Buddhist: 8.2,
          Muslim: 5.1,
          Kirat: 3.2,
          Christian: 1.8,
          Other : 0.5,
          Population: 'Religions',
        }
      ],
        populationDataSetLabels :  [
          { dataKey: 'Hindu', label: 'Hindu', stack: 'Religions' },
          { dataKey: 'Buddhist', label: 'Buddhist', stack: 'Religions' },
          { dataKey: 'Muslim', label: 'Muslim', stack: 'Religions' },
          { dataKey: 'Kirat', label:'Kirat', stack: 'Religions' },
          { dataKey: 'Christian', label:'Christian', stack: 'Religions' },
          { dataKey: 'Other', label:'Other', stack: 'Religions', color : 'black'}
        ]
    }
  ];
  
  export const nepalBarGraphData =[
    {    
      peopleGroupDataSet : [
        {
          Nepali : 44.9,
          Maithali : 11.1,
          Bhojpuri : 6.2,
          Tharu : 5.9,
          Tamang : 4.9, 
          Bajjika : 3.9, 
          Avadhi : 3, 
          Newari : 3,
          MagarDhut : 2.8, 
          Doteli: 1.7, 
          Urdu: 1.4, 
          Limbu: 1.2, 
          Gurung: 1.1, 
          other: 8.9,
          religion: 'People Groups',
        }
      ],
      peopleGroupDataSetLabels: [
        { dataKey: 'Nepali', label: 'Nepali' },
        { dataKey: 'Maithali', label: 'Maithali' },
        { dataKey: 'Bhojpuri', label: 'Bhojpuri' },
        { dataKey: 'Tharu', label: 'Tharu' },
        { dataKey: 'Tamang', label: 'Tamang' },
        { dataKey: 'Bajjika', label: 'Bajjika' },
        { dataKey: 'Avadhi', label: 'Avadhi' },
        { dataKey: 'Newari', label: 'Newari' },
        { dataKey: 'MagarDhut', label: 'MagarDhut' },
        { dataKey: 'Doteli', label: 'Doteli' },
        { dataKey: 'Urdu', label: 'Urdu' },
        { dataKey: 'Limbu', label: 'Limbu' },
        { dataKey: 'Gurung', label: 'Gurung' },
        { dataKey: 'other', label: 'other' },
      ],
    }
  
  ]
  
  export const PgnepalBulletPoints =[
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for Jesus to be made known among unreached peoples in hard-to-access locations.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for biblical training and Christlikeness for a rising generation of church leaders.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for the Living God to restore those devastated by the earthquakes.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for discernment, courage and faithfulness for those seeking to overturn two generations of negative opinion.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray that the Churchs may be kept from division and false preaching . Pray specifically for MFA supported ministries in Nepal as it seeks to provide a forum for prayer, sharing, unity among believers.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray also for the efforts of missionary families  facing issues against evangelism against Christians regarding arbitrary arrests, evangelism, property, discrimination and other maltreatments. ',
    },

  ];
  
  
  export const nepalImgList = [
    {
      img: baptism,
      title: "",
      author: "@nepal",
      rows: 2,
      cols: 2,
      featured: true
    },
    {
      img: np_group1,
      title: "",
      author: "@nepal",
    },
    {
      img: np_group2,
      title: "",
      author: "@nepal",
    },
    {
      img: np_group3,
      title: "",
      author: "@nepal",
      cols: 2
    },
    {
      img: np_cgroup1,
      title: "",
      author: "@nepal",
      rows: 2,
      cols: 2,
      featured: true
    },
    {
      img: np_cgroup2,
      title: "",
      author: "@nepal",
    },
    {
      img: np_cgroup3,
      title: "",
      author: "@nepal",
    },
    {
      img: np_gathering,
      title: "",
      author: "@nepal"
    }
  ];