import React from 'react'
import { Prayer, Participate } from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const PrayWithUs = () => {
  return (
    <>
    <div className="home-banner-container">
        <div className="home-bannerImage-container">
        
        </div>
     
        <div className="home-image-section">
        <img src={Prayer} alt="" className='page-banner'/>
        </div>
        <Container fixed>
        <Box sx={{ bgcolor: '#b9cee3', height: '300vh' }} >
        <div className="home-text-section">
            <h1 className="center-text">
                Pray with us
            </h1>
            <p className="">
                'Pray without ceasing;'' 1Thess 5:17
            </p>
            <h3 className="center-text">
            Join with us our Monthly Face to Face Missions Prayer Fellowship.
            </h3>
            <p className="">
                Missions Fellowship Australia has always been committed to prayer and seeking God. We do this as
                individuals, as ministry locations, as countries and at many other levels. One of MFA’s State level
                prayer initiatives is a monthly prayer gathering called “Missions Prayer Fellowship”.<br></br>
                <br></br>
                This prayer points from Overseas missions partners on monthly basis in respective ministry
                streamlines focuses on day to day ministry challenges / needs/ Spiritual Supports. We also engage
                missionaries and mission’s partners through ZOOM channel to share the testimonies.
            </p>
            <br></br><br></br>
            <h3>When?</h3>
        </div>
        <Grid container spacing={3}>
            <Grid item xs={2} md={2} lg={4}>
                <Card sx={{ minWidth: 345,maxWidth: 345 }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="140"
                        image=""
                        alt="State"
                        />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            NSW
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        The 2 nd Saturday of each month at 6:30pm, or on whatever day close to that works
                        better for local state members and sponsors availability in xxxxxxxxxxxxxxxxxxxxx
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={2} md={2} lg={4}>
                <Card sx={{ minWidth: 345,maxWidth: 345 }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="140"
                        image=""
                        alt="State"
                        />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            VIC
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        The 4 th Saturday of each month at 6:30pm, or on whatever day close to that works
better for local state members and sponsors availability in Baptist Church, 25/27 James
Street, Dandenong, VIC-3175.
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={2} md={2} lg={4}>
                <Card sx={{minWidth: 345 ,maxWidth: 345 }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="140"
                        image=""
                        alt="State"
                        />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            SA
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        The 1 st Saturday of each month at 5:00pm, or on whatever day close to that works
better for local state members and sponsors availability in 11 Broad Street, Queens
Town, Adelaide, SA-5014
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={2} md={2} lg={4}>
                <Card sx={{ minWidth: 345,maxWidth: 345 }}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="140"
                        image=""
                        alt="State"
                        />
                        <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            WA
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        The 2 nd Saturday of each month at 6:30pm, or on whatever day close to that works
better for local state members and sponsors availability in xxxxxxxxxxxxxxxxxxxxx
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            
        </Grid>
        <br></br>
        <h3>How to Participate</h3>
        <br></br>
        <div className="">
        <div className='home-text-section'>
        <img src={Participate} alt="" className=''/>
        </div>
        <div className='home-text-section-left '>
            <p className="">
                Reach out your State Coordinator by clicking above state link, alternatively to receive emails about
                missions updates and prayer points,
                <br></br>

                    <br></br>
                    Just sign up for our newsletter here at the bottom of this page.
            </p>
        </div>
        </div>
        </Box>
        </Container>
    </div>
    <br></br>
    </>
  )
}

export default PrayWithUs