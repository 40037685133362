import * as React from 'react';
import { useState } from "react";
import BottomNavigation from '@mui/material/BottomNavigation';
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import {MenuItems} from './InputData';
import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { Typography, Icon } from '@mui/material';

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: white;
  
`);

 function MinistryLinks() {
    const [activeMenucml, setActiveMenucml] = useState("getInvolved");
    const targetMenucml = MenuItems.find((article) => activeMenucml === article.mid);
    const navigate = useNavigate()
       return (
        <Box sx={{ flexGrow: 1 }}>
        <BottomNavigation sx={{ bgcolor: '#1e8a84', height : 100 }}
          showLabels
          
        >
            
            {targetMenucml.mitems.map(submitem => {
              return (
                        <div key={submitem.path}>
                        <BottomNavigationAction  icon={<Icon>{submitem.icon}</Icon>}  onClick={() => {
                          navigate(submitem.path)
                        }} label={
                                  <Typography variant="body1"  color={'white'}>
                                      {submitem.mtext}
                                  </Typography> } showLabel>
                        <Divider orientation="vertical" flexItem className='mfacolor_iconcolor' /></BottomNavigationAction>
                    
                        </div>
                );
                
            })}

         </BottomNavigation>
      </Box>
  );
}

export default MinistryLinks;