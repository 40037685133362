import React from 'react'
import { churchPlanting, cp_contactStateMissions, 
  cp_chooseYourMissioanry, cp_FolloeConnectPray,
  cp_country_india, cp_country_nepal, cp_country_srilanka,
} from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import PostItlabel from "../PostItlabel";
import BulletPoints from "../BulletPoints";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import VTSteps from "../VTSteps";
import ContactForm from "../ContactForm";
import PageSubTitle from "../PageSubTitle";
import GiveButton from "../GiveButton";
import MFADonateMenu from '../MFADonateMenu';



const ChurchPlanting = () => {

  const [SFormCP, setSFormCP] = useState(false);

  const ShFormCP = () => {
    setSFormCP(!SFormCP);
  }
  
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF' }}>
          <img src={churchPlanting} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Church Planting Ministry (CP)'/>
                <Typography variant="body1" color="" align="justify">
                <b>Send a missionary to a pioneer village to share the Good News of Jesus Christ.</b>
                </Typography>
                <Typography variant="body1" color="" align="justify">
                In partnership with mission organisations in South Asian countries, MFA sends a native missionary to remote villages to share the Gospel of Jesus Christ in a culturally relevant, non-coercive way. These missionaries are well trained and equipped by the partnering mission organisations.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='How to Sponsor a Missionary?'/>
                {/* <SponsorSteps steps={steps}/> */}
                <VTSteps details={steps}/>
              </Container>
          </Box>

          

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">  
              <PageSubTitle name='Sowing Your Seeds'/>
              <Box  sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {CPcountryList.map((country) => (
                      <Grid item  xs={4}>
                        <PostItlabel details={country}/>
                      </Grid>
                  ))}
                </Grid>
              </Box>
              </Container>
          </Box>

          

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Sponsor a Missionary'/>
                  <BulletPoints details={CPspMissionaryBulletPoints}/>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Be a Prayer Partner'/>
                <Typography variant="body1" color="" align="justify">
                  MFA has always been committed to prayer. You can join one of our <b>“Monthly Missions Prayer Fellowship”</b> in your local state and be part of the prayer team. You will also receive monthly MFA prayer newsletter to pray with your family and other believers.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Online Donation'/>
                <Typography variant="body1" color="" align="justify">
                  Click on the donate button to make an online donation today.
                <Stack direction="row" spacing={2}>
                  <MFADonateMenu />
                </Stack>
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='What Next ?'/>
                <BulletPoints details={CPnextBulletPoints}/>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Further Questions ?'/>
                <Typography variant="body1" color="" align="justify">
                  If you would like to know more, we encourage you to meet one of our mission team member face to face or feel free to send in your questions to us via email.
                </Typography>
                <br></br>
               
                
              </Container>
          </Box>

          <Grid container spacing={0} sx={{  height: 100}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const CPcountryList = [ 
  {
      mtext:'India',
      path: '/PgIndia',
      img: cp_country_india,
  },
  {    
      mtext:'Sri Lanka',
      path: '/PgSriLanka',
      img: cp_country_srilanka,
  },
  {    
      mtext:'Nepal',
      path: '/PgNepal',
      img:  cp_country_nepal,
  }
];



const steps = [
  {
    id:0,
    name:'Contact State Missions Team',
    img: cp_contactStateMissions,
  },
  {
    id:1,
    name:'Choose Your Missioanry',
    img: cp_chooseYourMissioanry, 
  },
  {
    id:2,
    name: 'Follow Connect & Pray',
    img: cp_FolloeConnectPray,
  }
];

const CPspMissionaryBulletPoints =[
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'You can partner with us and send a native missionary to a remote village in a South Asian country for $360/month. You can choose the country where you would like to send a missionary. We currently have missionaries placed in India, Nepal and Sri Lanka.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'You will receive a three-monthly ministry report with praise and prayer points from the mission field.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: '100% of your donation will go to the mission field and is not used for MFA administration. Based on your giving, you can be an either individual sponsor or a group sponsor where MFA will pool a number of sponsors together to support a native missionary.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'MFA encourages automatic recurring monthly payment by Electronic Fund Transfer (EFT).',
  },  
];

const CPnextBulletPoints =[
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will be given a registration ID & dedicated account number to transfer funds.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will receive a copy of your missionary’s profile through your registered email and testimony from the missionary for your regular prayers.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will be assigned a dedicated missionary block as soon as donation made through online or direct debit at your side.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Connect one of our State Missions Prayer Fellowship, your prayers are important for the success of their work in the missions field.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will receive regular reports about your supported ministry that you are sponsoring.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will be encouraged to participate in mission trips, MFA will facilitate your visit.',
  },
];

export default ChurchPlanting