import React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';



function CustomFundLink (pgLink) {
  switch(true) {
                        
    case(pgLink==='churchplanting'): {
            return (
              <Link to="route" target="_blank" rel="noopener noreferrer" underline="none"
                href="https://give.tithe.ly/?formId=1af2f060-adbf-42e5-afaa-17c6aedae09c&locationId=da432446-62d9-4f60-b04e-92e13843d4ac&fundId=046faa26-e381-47c1-ab6c-e2fd25e6f3fa" sx={{ color: 'inherit'}}
            >Give</Link>
            )
        }
    break;
        
    case(pgLink==='Radio_Button_Checked'): {
        return (
          <VolunteerActivismIcon sx={{ color: '#1e8a84' }}/>
        )
    }
    break;
    
    default: {
            return (
              <Link to="route" target="_blank" rel="noopener noreferrer" underline="none"
                href="https://give.tithe.ly/?formId=d941c06d-492e-4bef-ada2-64dce1ea31bf" sx={{ color: 'inherit'}}
            >Give</Link>
            )
        }
    break;
    }

}

const GiveButton = (props) => {
  return (
    <>
        <Button variant="outlined"  sx={ { borderRadius: 28,color:'white',backgroundColor: '#008ca8',
                    fontWeight: 'bold', fontSize: '20px', borderColor: 'white',
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' ,
                        color: '#008ca8', borderColor: '#008ca8'
                    },}}
                    endIcon={<VolunteerActivismIcon /> }>
            {CustomFundLink(props.page)}
        </Button>
    </>
  )
}

export default GiveButton