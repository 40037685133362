import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const PageSubTitle = (props) => {

    const PageTile = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#efefef' : '#efefef',
        ...theme.typography.h5,
        padding: theme.spacing(0),
        textAlign: 'left',
        color: '#1e8a84',
      }));

    const PageSTile = styled(Paper)(({ theme }) => ({
        //backgroundColor: theme.palette.mode === 'dark' ? '#efefef' : '#efefef',

        backgroundColor: '#FFF',
        ...theme.typography.h5,
        padding: theme.spacing(0),
        textAlign: 'left',
        color: '#1e8a84',
        fontWeight: 'bold',
        boxShadow: 'none',
      }));  

      const PStitlebar = styled(Paper)(({ theme }) => ({
        backgroundColor: '#e40000',
        width: '125px',
        height: '4px',
        margin: '0.75rem 0',
        padding: 0,
        }));

  return (
    <PageSTile>{props.name}
    <PStitlebar/>
    </PageSTile>
  )
}

export default PageSubTitle