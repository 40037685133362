import React from 'react'
import { mercyMinistry, iem,
  mm_contactStateMissions, mm_chooseShortTermProject, mm_FollowConnectPray,
  mm_country_cambodia, mm_country_india, mm_country_myanmar, mm_country_nepal, mm_country_srilanka,
} from "C:/react/mfa/src/img";
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import {PostItlabel} from "C:/react/mfa/src/components";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import VTSteps from "../VTSteps";
import ContactForm from "../ContactForm";
import PageSubTitle from "../PageSubTitle";
import BulletPoints from '../BulletPoints';
import MFADonateMenu from '../MFADonateMenu';

const MercyMinistry = () => {

  const [SFormMM, setSFormMM] = useState(false);

  const ShFormMM = () => {
    setSFormMM(!SFormMM);
  }

  return (
    <>
    <Grid sx={{ bgcolor: '#FFF'}}>
      <Grid>
        <Box sx={{ bgcolor: '#FFF' }}>
        <img src={mercyMinistry} alt="MFA" className='mfahomebanner'/>
        </Box>
      </Grid>
      <Grid container>
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Mercy Ministry (MM)'/>
              <Typography variant="body1" color="" align="justify">
              Mercy ministry provides a start-up fund and professional advice to enable highly vulnerable or disadvantaged families to establish a sustainable income generating business. Through these partnerships, we are also able to run empowerment programs for rural women to provide them with sewing training and materials to start up a sustainable business. In a culturally sensitive way, these families are given an opportunity to hear the gospel message. 
              </Typography>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='How to Sponsor a Benevolent Project?'/>
              <br></br>
              <VTSteps details={steps}/>
            </Container>
        </Box>
        
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">  
          <PageSubTitle name='Sow Your Seeds'/>
            <Box  sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {CPcountryList.map((country) => (
                    <Grid item  xs={4}>
                      <PostItlabel details={country}/>
                    </Grid>
                ))}
              </Grid>
            </Box>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Sponsor a Benevolent Project'/>
              <BulletPoints details={MMmercyBulletPoints} />
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Be a Prayer Partner'/>
                <Typography variant="body1" color="" align="justify">
                  MFA has always been committed to prayer. You can join one of our <b>“Monthly Missions Prayer Fellowship”</b> in your local state and be part of the prayer team. You will also receive monthly MFA prayer newsletter to pray with your family and other believers.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Online Donation'/>
                <Typography variant="body1" color="" align="justify">
                  Click on the donate button to make an online donation today.
                <Stack direction="row" spacing={2}>
                  <MFADonateMenu />
                </Stack>
                </Typography>
              </Container>
          </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Further Questions ?'/>
              <Typography variant="body1" color="" align="justify">
              If you would like to know more, we encourage you to meet one of our mission team member face to face or feel free to send in your questions to us via email.
              </Typography>
              <br></br>
              
            </Container>
        </Box>

        <Grid container spacing={0} sx={{  height: 50}}></Grid>
      </Grid>
    </Grid>
  </>
  )
}

const CPcountryList = [ 
  {
    mtext:'India',
    path: '/PgIndia',
    img: mm_country_india,
  },
  {
    mtext:'Sri Lanka',
    path: '/PgSriLanka',
    img: mm_country_srilanka,
  },
  {
      mtext:'Nepal',
      path: '/PgNepal',
      img:  mm_country_nepal, 
  },
  {    
      mtext:'Cambodia',
      path: '/PgCambodia',
      img: mm_country_cambodia,
  },
  {    
      mtext:'Myanmer',
      path: '/PageMyanmar',
      img:  mm_country_myanmar, 
  }
];

const steps = [
  {
    id:0,
    name:'Contact State Missions Team',
    img: mm_contactStateMissions,
  },
  {
    id:1,
    name:'Choose Your Benevolent  Project',
    img: mm_chooseShortTermProject, 
  },
  {
    id:2,
    name: 'Follow Connect & Pray',
    img: mm_FollowConnectPray,
  }
];

const MMmercyBulletPoints =[
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'You can partner with us to sponsor disadvantaged families to improve their livelihood and wellbeing for their generation.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: ' The cost to sponsor an individual family varies from $500 to $1500 (capped) based on their need. We currently sponsor benevolent projects in Cambodia, India, Myanmar, Nepal and Sri Lanka.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: '100% of your donation will go to the family in need and is not used for MFA administration. Based on your giving, you can either be an individual sponsor or a part of a group sponsorship where MFA will pool a number of sponsors together to support a family. Your donation in Australia is tax deductible.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'MFA encourages automatic recurring monthly payment by Electronic Fund Transfer (EFT).',
  },
];

export default MercyMinistry;
