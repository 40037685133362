import {game1, game2, game3, group1, group2, group3, child1, childusinglaptop} from '/react/mfa/src/img'

export const cambodiaBarPoldata =[
  {
    populationDataSet : [
      {
        Buddhist: 97.1,
        Muslim: 2,
        Christian: .3,
        Other : .5,
        Population: 'Religions',
      }
    ],
      populationDataSetLabels :  [
        { dataKey: 'Buddhist', label: 'Buddhist', stack: 'Religions' },
        { dataKey: 'Muslim', label: 'Muslim', stack: 'Religions' },
        { dataKey: 'Christian', label:'Christian', stack: 'Religions' },
        { dataKey: 'Other', label:'Other', stack: 'Religions', color : 'black'}
      ]
  }
];

export const cambodiaBarGraphData =[
  {
    peopleGroupDataSet : [
      {
        Khmer : 95.4,
        Cham : 2.4,
        Chinese : 1.5,
        other : 20.7,
        religion: 'People Groups',
      }
    ],
    peopleGroupDataSetLabels: [
      { dataKey: 'Khmer', label: 'Khmer' },
      { dataKey: 'Cham', label: 'Cham' },
      { dataKey: 'Chinese', label: 'Chinese' },
      { dataKey: 'other', label: 'other' },
    ],
  }

];

export const PgCambodiaBulletPoints =[
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Pray for a Gospel-driven end to corruption, drug trade, and human trafficking.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Pray for transformative and united prayer across Cambodian churches.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Pray for Jesus to bring great healing from the trauma of civil wars.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Pray for removing Spiritual darkness in Cambodia, from the many spirit shrines, the powerful grip of Buddhism that opposes Truth, and the widespread loss of morals. Only prayer will lift it. The enemy of souls makes people suffer under terrible abuses and oppressive poverty. The drug and sex trades hold power over many people. Criminals exploit the high number of unprotected children and young people. Land grabs by people with wealth and power enrich a few at the expense of the majority.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Pray for the light of the gospel to shine on individual lives and all social structures in Cambodia. Christians can make a difference in the areas of justice, rehabilitation, orphanages, healthcare, and development. Cambodia also needs outside help with projects in agriculture, fisheries, water management, and education.',
  },
];

export const cambodiaImgList = [
  {
    img: child1,
    title: "",
    author: "@nace-cambodia",
    rows: 2,
    cols: 2,
    featured: true
  },
  {
    img: group1,
    title: "",
    author: "@nace-cambodia",
  },
  {
    img: group2,
    title: "",
    author: "@nace-cambodia",
  },
  {
    img: group3,
    title: "",
    author: "@nace-cambodia",
    cols: 2
  },
  {
    img: game1,
    title: "",
    author: "@nace-cambodia",
    rows: 2,
    cols: 2,
    featured: true
  },
  {
    img: game2,
    title: "",
    author: "@nace-cambodia",
  },
  {
    img: game3,
    title: "",
    author: "@nace-cambodia",
  },
  {
    img: childusinglaptop,
    title: "",
    author: "@nace-cambodia"
  }
];