import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, Grid, Stack } from '@mui/material';



const MCMItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1f1e2c' : '#1f1e2c',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

 function FlipCard(props) {
    console.log(props.details);
  return (
<Grid container spacing={0} sx={{ flexGrow: 1 ,justifyContent:'center', }}>
    {props.details.map(fpCard => {
        return (
            <Grid item >
            <Box sx={{p:1, display: 'flex', justifyContent: 'center',alignItems:'center'}}>
            <div className='mfaFlipcard'>
                <Card  sx={{ width: 190 , height: 130, backgroundColor: '#008ca8'}} 
                className='cardFront'>
                    <CardContent>
                    <Typography  variant="h2" color="white" align="center"> 
                        {fpCard.fname}
                    </Typography>
                    
                    <Typography  variant="body1"  color="white" align="center"> 
                        {fpCard.name}
                    </Typography>
                    
                    </CardContent>
                    
                </Card>
                <Card  sx={{ width: 190 , height: 130, backgroundColor: 'black'}} 
                className='cardBack absolute'>
                    <CardMedia
                    component="img"
                    alt=''
                    
                    image={fpCard.img}
                    />
                   
                    
                </Card>
                </div></Box></Grid>
          );
          
      })}
    </Grid>
    
  );
}

export default FlipCard