import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import MLink from '@mui/material/Link';
import "./MfaNavBarNew.css"
import { MFALogo } from "../img";
import { Grid , Box, Stack} from '@mui/material';
import { MenuItems} from './InputData';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ChurchTwoToneIcon from '@mui/icons-material/ChurchTwoTone';
import HolidayVillageTwoToneIcon from '@mui/icons-material/HolidayVillageTwoTone';
import ManTwoToneIcon from '@mui/icons-material/ManTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import MFADonateMenu from './MFADonateMenu';

const MItem = styled(Paper)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    ...theme.typography.body2,
    padding: 2,
    textAlign: 'center',
    color: '#fff',
  }));

const Mtitle = styled(Paper)(({ theme }) => ({
backgroundColor: 'transparent',
boxShadow: 'none',
...theme.typography.h6,
padding: 2,
textAlign: 'left',
color: '#fff',
}));

const Mtitlebar = styled(Paper)(({ theme }) => ({
backgroundColor: '#E4002B',
width: '125px',
height: '3px',
margin: '0.75rem 0',
padding: 0,
}));

const Mshortdesc= styled(Paper)(({ theme }) => ({
backgroundColor: 'transparent',
boxShadow: 'none',
...theme.typography.body2,
padding: 2,
textAlign: 'left',
color: '#fff',
}));

const Donotebutton = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#FFF',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    boxShadow: 'none',
  }));
  
  const donateList =[
    {
        listId: 'listChurchPlanting',
        listPath: '',
        listTitle: 'Church Planting',
        listText:'Church Planting',
        listIcon: <ChurchTwoToneIcon sx={{color:'#008ca8'}}/>,
        submenu : [
            {
                sublistId: 'sublistChurchPlantingIndia',
                sublistPath: 'https://give.tithe.ly/?formId=1af2f060-adbf-42e5-afaa-17c6aedae09c',
                sublistTitle: 'India',
                sublistText:'Church Planting India'
            },
            {
                sublistId: 'sublistChurchPlantingNepal',
                sublistPath: 'https://give.tithe.ly/?formId=c29c425e-755c-4f4a-a66e-ef1ec782ec35',
                sublistTitle: 'Nepal',
                sublistText:'Church Planting Nepal'
            },
            {
                sublistId: 'sublistChurchPlantingSriLanka',
                sublistPath: 'https://give.tithe.ly/?formId=c29c425e-755c-4f4a-a66e-ef1ec782ec35',
                sublistTitle: 'Sri Lanka',
                sublistText:'Church Planting Sri Lanka'
            }
        ]
    },   
    {
        listId: 'listVillageTransformation',
        listPath: '',
        listTitle: 'Village Transformation',
        listText:'Village Transformation',
       listIcon: <HolidayVillageTwoToneIcon sx={{color:'#008ca8'}}/>,
        submenu : [
            {
                sublistId: 'sublistVillageTransformationCambodia',
                sublistPath: '',
                sublistTitle: 'Cambodia',
                sublistText:'Church Planting Cambodia'
            },
            {
                sublistId: 'sublistVillageTransformationIndia',
                sublistPath: '',
                sublistTitle: 'India',
                sublistText:'Church Planting India'
            },
            {
                sublistId: 'sublistVillageTransformationMyanmar',
                sublistPath: '',
                sublistTitle: 'Myanmar',
                sublistText:'Church Planting Myanmar'
            },
            {
                sublistId: 'sublistVillageTransformationNepal',
                sublistPath: '',
                sublistTitle: 'Nepal',
                sublistText:'Church Planting Nepal'
            },
            {
                sublistId: 'sublistVillageTransformationSriLanka',
                sublistPath: '',
                sublistTitle: 'Sri Lanka',
                sublistText:'Church Planting SriLanka'
            },
        ]
    },   
    {
        listId: 'listLifeTransformation',
        listPath: '',
        listTitle: 'Life Transformation',
        listText:'Life Transformation',
       listIcon: <ManTwoToneIcon sx={{color:'#008ca8'}}/>,
        submenu : [
            {
                sublistId: 'sublistLifeTransformationCambodia',
                sublistPath: '',
                sublistTitle: 'Cambodia',
                sublistText:'Life Transformation Cambodia'
            },
            {
                sublistId: 'sublistLifeTransformationMyanmar',
                sublistPath: '',
                sublistTitle: 'Myanmar',
                sublistText:'Life Transformation Myanmar'},
            {
                sublistId: 'sublistLifeTransformationSriLanka',
                sublistPath: '',
                sublistTitle: 'Sri Lanka',
                sublistText:'Life Transformation SriLanka'
            }
        ]
    }, 
    {
        listId: 'listMercyMinistry',
        listPath: '',
        listTitle: 'Mercy Ministry',
        listText:'Mercy Ministry',
       listIcon: <FavoriteTwoToneIcon sx={{color:'#008ca8'}}/>,
        submenu : [],
    }, 
  ];
 
function MfaNavBarNew() {
    const [dropdown, setDropdown] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("aboutMFA");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
     const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        //console.log(event.currentTarget.id);
        setDropdown('true');    
        setActiveMenu(event.currentTarget.id);
        
      };
      const handleClose = () => {
        setAnchorEl(null);
        setDropdown('false'); 
        setMenuOpen(!menuOpen);
      };

 

      
    
     
    
    const targetMenu = MenuItems.find((article) => activeMenu === article.mid);
  return (
    <Grid container   sx={{ flexGrow: 1 }} spacing={0}>
        <nav>
            <Grid className='ribbionMenu'>
                <Grid  item xs={3} md={3} lg={2}>
                    <Grid>
                        <Link to="/"><img src={MFALogo} alt="Missions Fellowship Australia" to={"/"} className="mfalogo" /></Link>
                    </Grid>
                </Grid>
                <Grid container item xs={6} md={6} lg={8} >
                    <Grid item xs={12} md={12} lg={12} className='div-padding'>
                        <Grid>
                            <Link to="/" className='title'>Missions Fellowship Australia</Link>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} >
                        <div>
                            <ul className={menuOpen ? "open" :""}>
                                {MenuItems.map(mitem => {
                                return (
                                    <li key={mitem.mid}>
                                        <NavLink   id={mitem.mid} 
                                            onClick={handleClick}
                                        > 
                                                {mitem.mtitle}
                                        </NavLink>
                                        <Divider orientation="vertical" flexItem  />
                                    </li>);
                                })}
                                
                                <Menu PaperProps={{
                                    style: {
                                    width: "100%",
                                    height: "40%",
                                    maxWidth: "100%",
                                    
                                    left: 0,
                                    right: 0,
                                    background: "linear-gradient(rgb(1, 33, 105),transparent)",
                                    backgroundColor: "#012169",
                                    }
                                }}
                                    id="fade-menu"
                                    MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                    }}
                                    
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                    
                                >
                                    <Grid container spacing={0}>
                                    <Grid item xs={1}></Grid> 
                                    <Grid item xs={3}>
                                    
                                    {targetMenu.mitems.map(submitem => {
                                        return (
                                            <MenuItem key={submitem.path} onClick={handleClose} divider="true"><Link to={submitem.path} className='text-decor-none'><MItem sx={{fontSize: '1.1rem'}}>{submitem.mtext}</MItem></Link> </MenuItem>
                                            );
                                            
                                    })}
                                        
                                    </Grid>
                                    <Grid item xs={6} className='mobileMencDesp'>
                                        <Mtitle>{targetMenu.mtitle}
                                        </Mtitle>
                                        <Mtitlebar />
                                        <Mshortdesc  sx={{fontSize: '1rem',textAlign: 'justify'}}>{targetMenu.mshortdesc}</Mshortdesc>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                    </Grid>
                                </Menu>
                                
                            </ul>
                        </div>
                    </Grid>
                </Grid>
                <Grid item  xs={3} md={3} lg={2}>
                <MFADonateMenu />
                </Grid>
            </Grid>
        </nav>
        <nav>
        
            <Grid className='mobileMenu'>

                            <Grid container spacing={0} sx={{ flexGrow: 1 ,justifyContent:'center', }}  className='div-padding'> 
                                  <Grid> 
                                    <div className='shortmenu' onClick={() =>{
                                            setMenuOpen(!menuOpen);
                                        }}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>                         
                                    <Link to="/" className='shorttitle'>Missions Fellowship Australia</Link>
                                    <div>
                                        <ul className={menuOpen ? "open" :""}>
                                            {MenuItems.map(mitem => {
                                            return (
                                                <li key={mitem.mid}>
                                                    <NavLink   id={mitem.mid} 
                                                        onClick={handleClick}
                                                    > 
                                                            {mitem.mtitle}
                                                    </NavLink>
                                                    <Divider orientation="vertical" flexItem  />
                                                </li>);
                                            })}
                                          
                                        </ul>
                                    </div>
                                    <MFADonateMenu />
                                    </Grid> 
                                    <Grid className='mobileDonateBtn'>
                                    
                                    </Grid>
                                    
                                
                                    
                                    
                                
                            </Grid>
                            
                   
                    
                </Grid>
                
                    
                
                
                
            
        </nav>
       
    </Grid>
    
  )
}



export default MfaNavBarNew;