import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const chartSetting = {
  xAxis: [
    {
      label: 'Religions',
      labelStyle: 'fixLabel'
    },
    ],
  height: 150,
  slotProps: {
    legend: {
      direction: 'row',
      position: { vertical: 'top', horizontal: 'middle' },
      padding: -5,
    },
  },
};

const BarGraphPol = (props) => {
    return (
      
        <>
        <BarChart 
          dataset={props[0].populationDataSet}
          series={props[0].populationDataSetLabels}
          xAxis={[{ scaleType: 'band' }]}
          layout="horizontal"
          borderRadius={10}  
          leftAxis={null}
          bottomAxis={null}
          {...chartSetting}
          tooltip={{trigger: 'item' }}
        /> </>
      )
}

export default BarGraphPol