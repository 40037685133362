import React from 'react';
import { BannerImage} from "C:/react/mfa/src/img";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const GetInvolved = () => {
  const navigate = useNavigate()
  return (
    <>
    <div className="home-banner-container">
     <div className="home-bannerImage-container">
       
     </div>
     
     <div className="home-image-section">
       <img src={BannerImage} alt="" className='page-banner'/>
     </div>
     <div className="home-text-section">
       <h1 className="center-text">
       Get Involved
       </h1>
       <p className="">
       'The harvest is plentiful, but the laborers are few; therefore pray earnestly to the Lord of the harvest to send out
laborers into his harvest.'Mat 9;37
       </p>
     </div>
    

   </div>
   <br></br>
  <Grid container  spacing={0} className='card-banner bg-mfacolor'>
    
      <Card sx={{ maxWidth: 345 }} className='card-padding'>
        <CardMedia
          component="img"
          alt="Pray with Us"
          height="140"
          image="https://www.mfa.org.au/wp-content/uploads/2015/08/prayer.jpg"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Pray with Us
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Join us in prayer as we ask for God’s will to be done and we listen for His voice. Join MFA’s monthly Prayer Plus Electronic Newsletter and partner with us in prayer.
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Share</Button>
          <Button size="small" onClick={() => {
                            navigate("/PrayWithUs")
                            }} >Learn More</Button>
        </CardActions>
      </Card>
    
    
    
    
    
      
    
    

    <Card sx={{ maxWidth: 345 }} className='card-padding'>
      <CardMedia
        component="img"
        alt="Go on a Missions Trip"
        height="140"
        image="https://www.mfa.org.au/wp-content/uploads/2016/11/Karadipooval.jpg"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Go on a Missions Trip
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Whether your interest is construction, medicine, sports, arts, compassion, evangelism, or all of the above, MFA has a mission trip for you. There are trips for families and children, programs for churches, and you can also go with your youth group or adult group. with US PPPP
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small" onClick={() => {
                            navigate('/ConnectInPrayer')
                            }} >Learn More</Button>
      </CardActions>
    </Card>

    <Card sx={{ maxWidth: 345 }}  className='card-padding'>
        <CardMedia
          component="img"
          alt="Connect Through by Giving"
          height="140"
          image="https://www.mfa.org.au/wp-content/uploads/2018/09/100.jpg"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Connect Through by Giving
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Learn how to partner with MFA financially. Help MFA share the good news of Jesus and care for people in Southeast Asia Region. y with US PPPP
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">Share</Button>
          <Button size="small" onClick={() => {
                            navigate('/ConnectInPrayer')
                            }} >Learn More</Button>
        </CardActions>
      </Card>

    <Card sx={{ maxWidth: 345 }}  className='card-padding'>
      <CardMedia
        component="img"
        alt="Become MFA State Team"
        height="140"
        image="https://www.mfa.org.au/wp-content/uploads/2015/08/motorbike.jpg"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
        Become MFA State Team
        </Typography>
        <Typography variant="body2" color="text.secondary">
          You can join MFA to Serve Lord for a couple of years or stay for life. Grow in State Missions Team &amp; leadership, pioneer new ministries and grow established ministries. y with US PPPP
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small" onClick={() => {
                            navigate('/ConnectInPrayer')
                            }} >Learn More</Button>
      </CardActions>
    </Card>
  </Grid>
 </>
  )
}

export default GetInvolved