import React from 'react'
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function bulletIcon (iconItem) {
  switch(true) {
                        
    case(iconItem==='Check_Circle'): {
            return (
              <CheckCircleIcon sx={{ color: '#1e8a84' }}/>
            )
        }
    break;
        
    case(iconItem==='Radio_Button_Checked'): {
        return (
          <RadioButtonCheckedIcon sx={{ color: '#1e8a84' }}/>
        )
    }
    break;
        
    case(iconItem==='Radio_Button_CheckedWhite'): {
      return (
        <RadioButtonCheckedIcon sx={{ color: 'white', background: '#1e8a84' }}/>
      )
  }
  break;

    default: {
            return (
              <CheckCircleIcon sx={{ color: '#1e8a84' }}/>
            )
        }
    break;
    }

}

const BulletPoints = (props) => {

    const BulletItem = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#FFF',
        ...theme.typography.body1,
        padding: theme.spacing(0),
        textAlign: 'center',
        boxShadow: 'none'
      }));

  return (
    <Typography variant="body1" color="" align="justify">
        <Grid container >
            {props.details.map(bulletItem => {
              return (
                <><Grid xs={1} ><BulletItem sx={{ background: bulletItem.icon==='Radio_Button_CheckedWhite' ? '#1e8a84' : 'white' }}> {bulletIcon (bulletItem.icon)}</BulletItem></Grid>
                <Grid xs={11}>{bulletItem.text}<br></br></Grid></>
                );
                
            })}
  
        </Grid>
    </Typography>
  )
}

export default BulletPoints