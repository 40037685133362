import React from 'react';
import { group } from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PageSubTitle from "../PageSubTitle";
import BulletPoints from "../BulletPoints";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const HowWeWork = () => {
  return (
    <>
    <Grid sx={{ bgcolor: '#FFF'}}>
      <Grid>
        <Box sx={{ bgcolor: '#FFF'}}>
        <img src={group} alt="MFA" className='mfahomebanner'/>
        </Box>
      </Grid>
      <Grid container>
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">
          <PageSubTitle name='Our Members and Governance'/>
            <Typography variant="body1" color="" align="justify">
          The members of MFA are volunteers who are passionate about sharing the Gospel of the Lord Jesus Christ with love and care to people in South Asian countries. 
Members of MFA undertake field visits on a regular basis, at their own cost, to ensure mission objectives are achieved.
MFA is governed by a team of nine members and are accountable to each other. 
MFA has no paid workers in Australia.<br></br>

             Our volunteers or mission coordinators are passionate about the great commission of our Lord Jesus Christ and their faith is centered on the Bible, which they 
             apply on a daily basis in every mission work. 
          </Typography>
          </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">
          <PageSubTitle name='Overseas Missions Partnership'/>
            <Typography variant="body1" color="" align="justify">
              MFA facilitates missions in South Asian countries through indigenous mission organisations who share the same vision as MFA. 
MFA in partnership with indigenous mission organisations identifies ministries in places where the Gospel has not been taken and places a missionary there on a long-term basis. 
<br></br><br></br>
A memorandum of understanding is established between MFA and indigenous mission organisations (partners) with clear accountabilities.  
Each missionary is connected to a sponsor(s) in Australia. 

                
                
              </Typography>
          </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">
          <PageSubTitle name='Our Sponsors'/>
            <Typography variant="body1" color="" align="justify">
              Our sponsors are local churches, families and individuals who are committed to share the love of God to unreached people in South Asian countries. Sponsorships can be short-term, long-term and/or one-off based on the ministry need.
                
              </Typography>
          </Container>
        </Box>
        
       

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Memorandum of Understanding'/>
              <Typography variant="body1" color="" align="justify">
                A memorandum of understanding is a contract document that is generally established between MFA & overseas missions partners with clear accountabilities followed by adequate reporting mechanisms for operation of mission projects.
                <br></br><br></br>
                MFA team undertake field visits on a regular basis to ensure mission objectives are achieved. Local partners and sponsors are also encouraged to visit the mission field to actively interact with missionaries.
              </Typography>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='State Missions Team'/>
              <Typography variant="body1" color="" align="justify">
              We encourage people to get involved in the cross-cultural monthly mission prayer fellowship engagements.
Monthly prayer fellowships are held across Australia through our inter-state mission operations. The state 
mission team coordinators are responsible for the following local mission activities:
<br></br> </Typography>
                <BulletPoints details={HowWeWorkBulletPoints}/>
             
            </Container>
        </Box>
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
      </Grid>
    </Grid>
  </>
  )
}

const HowWeWorkBulletPoints =[
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Organising mission connect meetings.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Engaging local churches.',
  }, 
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Organising mission trips.',
  }, 
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Conducting monthly mission prayer fellowships.',
  }, 
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Enrolment of new sponsor registrations(s).',
  }, 
];


export default HowWeWork