import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const chartSetting = {
  xAxis: [
    {
      label: 'Percentage %',
      
    },
  ],
 labelStyle: 'fixLabel',
  height: 450,
  slotProps: {
    legend: {
      direction: 'row',
      position: { vertical: 'top', horizontal: 'middle' },
      padding: -5,
    },
  },
};

const BarGraph = (props) => {
    return (
        <BarChart 
          dataset={props[0].peopleGroupDataSet}
          yAxis={[{ scaleType: 'band', dataKey: 'religion' }]}
          series={props[0].peopleGroupDataSetLabels}
          layout="horizontal"
          grid={{ vertical: true }}
          borderRadius={10}  
          
          {...chartSetting}
        />
      )
}

export default BarGraph