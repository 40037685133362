import React from 'react'
import { Grid } from '@mui/material';
import { aboutMFAAP, acnc , dgr} from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PageSubTitle from "../PageSubTitle";
import LinkCards from '../LinkCards';

const AboutMFAAP = () => {
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF' }}>
          <img src={aboutMFAAP} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Missions Fellowship Asia Pacific (MFAP)'/>
                <Typography variant="body1" color="" align="justify">
                Missions Fellowship Asia Pacific is an association established to address the holistic needs of impoverished and vulnerable individuals living in remote places in the Asia-Pacific region. <br></br>
Both MFA and MFAP work under the same governance board and are registered with the Australian Charity and Not-for Profits Commission (ACNC).<br></br>
While we are distinctly a Christian organisation, we assist children, young adults and their families living in poverty regardless of their beliefs, gender or background. 

                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='ACNC Registered Missions Organisation'/>
              <Grid container>
                <Grid item sx={{ pt:1,pl:5 ,display: 'flex', justifyContent: 'center'}}>
                 
                    <img src={acnc} alt='ACNC: 93973196980' className="MFAPlogo"/>
                  
                </Grid>
                <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs sx={{ pt:3 }}>
                    <Typography variant="body1" color="" align="justify">
                    Missions Fellowship Asia Pacific is registered as a Public Benevolent Institution (PBI) with the Australian Charity and Not-for Profits Commission (ACNC).<br></br>
100% of your donation is sent to benevolent projects in South Asia. All donations made towards a public benevolent project including ‘sponsoring a child at risk’ and ‘supporting a young adult’ are tax deductible for donors in Australia. Donors will be given a receipt at the end of the financial year. Accounts are independently audited and reported to the ACNC (ACNC: 93973196980).
                    </Typography>
                  </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box>
          
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Deductible Gift Receipt (DGR)'/>
              <Grid container>
                <Grid item sx={{ pt:1,pl:5 ,display: 'flex', justifyContent: 'center'}}>
                 
                    <img src={dgr} alt='DGR' className="MFAPlogo"/>
                  
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs sx={{ pt:3 }}>
                      <Typography variant="body1" color="" align="justify">
                        Donations towards <b>Village Transformation (Child Sponsorship)</b> ,  
                        <b> Life Transformation (Young Adult Sponsorship)</b> and <b>Mercy Ministry (one off public Benovalant Project)</b>, which are endorsed as 
                        Deductible Gift Recipients (DGR), are eligible for income tax deductions. This means that 
                        when you make a gift or contribution to a DGR-endorsed ministry, you may be able to 
                        claim a tax deduction. This means when you make a gift or contribution to a DGR endorsed ministry, you may be able to claim a tax deduction. The amount of the claim will depend on the type of gift or contribution you make. MFA will send you a receipt at the end of financial year of your entire contribution. 
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box> 
           
          </Box>
          
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="">
            <Grid container spacing={0} sx={{ flexGrow: 1 ,justifyContent:'center', }}> 
              <LinkCards details='villagetransformation'/>
              <LinkCards details='lifetransformation'/>
              <LinkCards details='mercyministry'/>
              </Grid>
            </Container>
          </Box>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}


export default AboutMFAAP