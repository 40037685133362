import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import { useNavigate } from 'react-router-dom';

const OuterItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#eee' : '#eee',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  boxShadow: 'none',
}));

const PILItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#379ac1' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: 25,
}));


 const PostItlabel = (props) => {
  const navigate = useNavigate();
  return (
    <OuterItem>
      <CardActionArea  onClick={() => {
                    navigate(props.details.path)
                    }}>
      <Card PILItem sx={{ bgcolor: '#008ca8' }} >
        <Typography   bgcolor={'#141416'}  variant="h6" color='white' 
        component="div" align='center'> 
          
        </Typography>
        <CardMedia
          component="img"
          alt={props.details.mtext} 
         
          
          image={props.details.img}
        />
      </Card>
      </CardActionArea>
    </OuterItem>
  )
}

export default PostItlabel;