import React from 'react'
import { villageTransformation, gems, 
  vt_chooseYourChild, vt_contactStateMissions, vt_FollowConnectPray,
  vt_country_cambodia, vt_country_myanmar, vt_country_nepal, vt_country_srilanka,
  CleanWater, development, healthCare, introducingJesus, learning} from "C:/react/mfa/src/img";
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import {PostItlabel, GenTab} from "C:/react/mfa/src/components";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import VTSteps from "../VTSteps";
import ContactForm from "../ContactForm";
import PageSubTitle from "../PageSubTitle"; 
import BulletPoints from "../BulletPoints";
import GiveButton from '../GiveButton';
import FlipCard from '../FlipCard';
import MFADonateMenu from '../MFADonateMenu';


const BulletItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#FFF',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  boxShadow: 'none',
}));


const VillageTransformation = () => {

  const [SFormVT, setSFormVT] = useState(false);

  const ShFormVT = () => {
    setSFormVT(!SFormVT);
  }

  return (
    <>
    <Grid sx={{ bgcolor: '#FFF'}}>
      <Grid>
        <Box sx={{ bgcolor: '#FFF' }}>
        <img src={villageTransformation} alt="MFA" className='mfahomebanner'/>
        </Box>
      </Grid>
      <Grid container>
      <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Village Transformation (VT)'/>
                <Typography variant="body1" color="" align="justify">
                <b>Provide a life changing difference to a child living in hopelessness and uncertainty. Children and their families are given opportunities to hear the gospel message in a culturally sensitive manner.</b>
                </Typography>
                <Typography variant="body1" color="" align="justify">
                The children in the program are supported to complete their education through . attend and stay in the school. They are provision of before and after school care and tuition for selected subjects.
              <br></br><br></br>
              They are also supported to learn, grow and play in a loving, safe community facilitated through child development programs run by our partnering organisations which include sport, field trips, music and computer training. Our approach to care is holistic with access to medical camps and social awareness programs. 
                </Typography>
              </Container>
          </Box>
      

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='How to Sponsor a Child at risk?'/>
              <br></br>
              <VTSteps details={steps}/>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='By Sponsoring a child, you will help to achieve on following areas:'/>
            </Container>
              <FlipCard details={Vtflipcards}/>
              <br></br>
              <Container maxWidth="md">
              <Typography variant="body1" color="" align="justify">
                MFA values integrity and accountability. For this reason, we link arms with other evangelical mission organizations and submit to the Godly leadership and governance body.
              </Typography>
            </Container>
        </Box>

 

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">  
          <PageSubTitle name='Sowing Your Seeds'/>
            <Box  sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {CPcountryList.map((country) => (
                    <Grid item  xs={3}>
                      <PostItlabel details={country}/>
                    </Grid>
                ))}
              </Grid>
            </Box>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Sponsor a Child at Risk'/>
              <Typography variant="body1" color="" align="justify">
                <b> Sponsoring a child at risk makes a life-changing difference to a child living in hopelessness and uncertainty, giving the hope of a better future. Child sponsorship is a long-term commitment as children are brought into the development program at an early age. The commitment will be until the child reaches the age of 21, depending on the needs of the child. MFA encourages you to pray for, write to and visit your sponsored child.</b><br></br>
                <br></br>
                
              </Typography>
              <BulletPoints details={VTchildRiskBulletPoints}/>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Be a Prayer Partner'/>
              <Typography variant="body1" color="" align="justify">
                MFA has always been committed to prayer. You can join one of our <b>“Monthly Missions Prayer Fellowship”</b> in your local state and be part of the prayer team. You will also receive monthly MFA prayer newsletter to pray with your family and other believers.
              </Typography>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Online Donation'/>
                <Typography variant="body1" color="" align="justify">
                  Click on the donate button to make an online donation today.
                <Stack direction="row" spacing={2}>
                  <MFADonateMenu />
                </Stack>
                </Typography>
              </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='What Next ?'/>
              <Typography variant="body1" color="" align="justify">
                <BulletPoints details={VTnextBulletPoints}/>
              </Typography>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
              <PageSubTitle name='Further Questions ?'/>
              <Typography variant="body1" color="" align="justify">
              If you would like to know more, we encourage you to meet one of our mission team member face to face or feel free to send in your questions to us via email.
              </Typography>
              <br></br>
              
            </Container>
        </Box>

        <Grid container spacing={0} sx={{  height: 50}}></Grid>
      </Grid>
    </Grid>
  </>
  )
}

const CPcountryList = [ 
  {
    mtext:'PgCambodia',
    path: '/PgCambodia',
    img: vt_country_cambodia,
  },
  {
      mtext:'India',
      path: '/PgIndia'
  },
  {    
      mtext:'Myanmar',
      path: '/PageMyanmar',
      img: vt_country_myanmar, 
  },
  {    
      mtext:'Nepal',
      path: '/PgNepal',
      img: vt_country_nepal,
  },
  {    
      mtext:'Sri Lanka',
      path: '/PgSriLanka',
      img: vt_country_srilanka,
  }
];

const steps = [
  {
    id:0,
    name:'Contact State Missions Team',
    img: vt_contactStateMissions,
  },
  {
    id:1,
    name:'Choose Your Child',
    img: vt_chooseYourChild, 
  },
  {
    id:2,
    name: 'Follow Connect & Pray',
    img:vt_FollowConnectPray,
  }
];

const Vtflipcards = [
  {
    id: 101,
    fname: 'C',
    name: 'Clean Water',
    img: CleanWater,
  },
  {
    id: 102,
    fname: 'H',
    name: 'Health Care',
    img: healthCare,
  },
  {
    id: 103,
    fname: 'I',
    name: 'Introduce Jesus',
    img: introducingJesus,
  },
  {
    id: 104,
    fname: 'L',
    name: 'Learning',
    img: learning,
  },
  {
    id: 105,
    fname: 'D',
    name: 'Development',
    img: development,
  },
]

const VTchildRiskBulletPoints =[
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'You can sponsor a child with little as $36 per month.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'We currently have child sponsorship programs in Cambodia, India, Nepal and Sri Lanka.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: '100% of your donation will go to the program and is not used for MFA administration.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Your donation in Australia is tax deductible.',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'MFA encourages automatic recurring monthly payment by Electronic Fund Transfer (EFT).',
  },
];

const VTnextBulletPoints =[
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will be given a registration ID & dedicated account number to transfer funds.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will receive a copy of your child profile through your registered email.'
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Connect with one of our State Missions Prayer Fellowship team for regular gathering.'
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Your prayers are important for the success of child progress.'
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will receive regular reports about your supported child that you are sponsoring.'
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'You will be encouraged to participate in mission trips, MFA will facilitate your visit.'
  },
];

export default VillageTransformation