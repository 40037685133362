import {gathering,ind_group1,ind_group2, ind_group3, outdoor1, outdoor2, prayer1, prayer2,} from '/react/mfa/src/img'

export const indiaBarPoldata =[
    {
      populationDataSet : [
        {
          Hindu : 79.8,
          Muslim: 14.2,
          Christian: 2.3,
          Sikh : 1.7,
          Other : 2,
          Population: 'Religions',
        }
      ],
        populationDataSetLabels :  [
          { dataKey: 'Hindu', label: 'Hindu', stack: 'Religions' },
          { dataKey: 'Muslim', label: 'Muslim', stack: 'Religions' },
          { dataKey: 'Christian', label:'Christian', stack: 'Religions' },
          { dataKey: 'Sikh', label:'Sikh', stack: 'Religions' },
          { dataKey: 'Other', label:'Other', stack: 'Religions', color : 'black'}
        ]
    }
  ];
  
  export const indiaBarGraphData =[
    {
      peopleGroupDataSet : [
        {
          IndoAryan : 72,
          Dravidian  : 25,
          other : 3,
          religion: 'People Groups',
        }
      ],
      peopleGroupDataSetLabels: [
        { dataKey: 'IndoAryan', label: 'IndoAryan' },
        { dataKey: 'Dravidian', label: 'Dravidian' },
        { dataKey: 'other', label: 'other' },
      ],
    }
  
  ]
  
  export const PgindiaBulletPoints =[
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for greater access to aid and education for millions suffering in abject poverty.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: "Pray for Christians to model God's love across religious, regional and caste barriers.",
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for believers to have courageous and bold faith in the face of rising persecution.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: "Pray for open doors for Gospel resources to reach India's 1 billion unreached people.",
    },
   
  ];
  
  export const indiaImgList = [
    {
      img: gathering,
      title: "",
      author: "@gems",
      rows: 2,
      cols: 2,
      featured: true
    },
    {
      img: ind_group1,
      title: "",
      author: "@gems",
    },
    {
      img: ind_group2,
      title: "",
      author: "@gems",
    },
    {
      img: ind_group3,
      title: "",
      author: "@gems",
      cols: 2
    },
    {
      img: outdoor1,
      title: "",
      author: "@gems",
      rows: 2,
      cols: 2,
      featured: true
    },
    {
      img: outdoor2,
      title: "",
      author: "@gems",
    },
    {
      img: prayer1,
      title: "",
      author: "@gems",
    },
    {
      img: prayer2,
      title: "",
      author: "@gems"
    }
  ];