import React from 'react'
import { missionsTrip } from "C:/react/mfa/src/img";
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import ContactForm from "../ContactForm";
import PageSubTitle from "../PageSubTitle";
import BulletPoints from "../BulletPoints";

const MissionsTrip = () => {

  const [SFormSP, setSFormSP] = useState(false);

  const ShFormSP = () => {
    setSFormSP(!SFormSP);
  }

    return (
    <>
    <Grid sx={{ bgcolor: '#FFF'}}>
      <Grid>
        <Box sx={{ bgcolor: '#FFF' }}>
        <img src={missionsTrip} alt="MFA" className='mfahomebanner'/>
        </Box>
      </Grid>
      <Grid container>
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Are you interested in missions trip ? '/>
              <Typography variant="body1" color="" align="justify">
              A short-term and long-term mission opportunity with MFA 
can range from one to four weeks, offering tailored mission trips for individuals, churches, 
youth groups, and students based on their preferred timeframe.
              </Typography>
            </Container>
        </Box>
        
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Missions Field Visit'/>
              <Typography variant="body1" color="" align="justify">
              One of MFA's most popular short-term mission programs is the Missions Field Visit.
              </Typography>
              <br></br>
              <Typography variant="body1" color="" align="justify">
              Some mission trip opportunities include:<br></br>
              <BulletPoints details={ministryTripBulletPoints}/>
                
              </Typography>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
              <PageSubTitle name='Further Questions'/>
              <Typography variant="body1" color="" align="justify">
              Please contact your local state missions team coordinator for further information. 
              </Typography>
              <br></br>
              
            </Container>
        </Box>

        <Grid container spacing={0} sx={{  height: 50}}></Grid>
      </Grid>
    </Grid>
  </>
  )
}

const ministryTripBulletPoints =[
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Evangelism',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Medical assistants',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Teachers',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Sports teams',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Trips for your church group',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Trips for teens to explore missions fields',
  },
];

export default MissionsTrip;