import React from 'react'
import { Grid } from '@mui/material';
import { pg_india, ind_promo  } from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PageSubTitle from "../PageSubTitle";
import BulletPoints from '../BulletPoints';
import BarGraph from '../BarGraph';
import BarGraphPol from '../BarGraphPol';
import { PgindiaBulletPoints } from "/react/mfa/src/dataset/india";
import { indiaBarPoldata, indiaBarGraphData } from "/react/mfa/src/dataset/india";
import Link from '@mui/material/Link';
import { indiaImgList } from '../../dataset/india';
import ImageListApp from '../ImageListApp'

const PgIndia = () => {
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF'}}>
          <img src={pg_india} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='About India'/>
                  <Typography variant="body1" color="" align="justify">
                  One of the largest and most diverse countries in the world, India is filled with more than 2,500 distinct people groups who speak over 19,500 languages. The most commonly spoken language is Hindi. One billion-plus people are dispersed over one million square miles.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  When it comes to the spread of the Gospel, India has the most unreached people groups in the world. In fact, 95% of Indians have never heard of Jesus! While Hinduism is practiced by roughly 72.4%, only about 5.7% identify as Christians. But because of the population, even minorities (Buddhists, Jains, and Sikhs) number in the millions each.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  Christianity is still seen as a threat to the traditional Hindu way of life. Hindu nationalist groups want to make India a “Hindu Nation.” Anti-conversion laws make it dangerous to share the Gospel. Those who choose to follow Jesus are often threatened with losing their status, jobs, homes, and even lives. Several Christian organizations have been forced out. However, despite the persecution, Indian Christians are courageously persisting as the hands and feet of Jesus to their neighbors.
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by Religion'/>
                  <BarGraphPol {...indiaBarPoldata}/>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/india/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by People Group'/>
                  <Typography variant="body1" color="" align="justify">
                  <BarGraph {...indiaBarGraphData}/>
                  </Typography>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/india/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>
          
            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='How can you pray for India ?'/>
                  <Typography variant="body1" color="" align="justify">
                  People of India have multiple languages, cross cultural environment predominately 80% of peoples worship idols, involving witch craft, black magics, and Sprite worship, animal worship. 
                  </Typography>
                  <br></br>
                   <BulletPoints details={PgindiaBulletPoints} />
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='MFA Ministries in India'/>
                  <Typography variant="body1" color="" align="justify">
                    <ImageListApp imglist={indiaImgList}/>
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Will you support one of our missions projects ?'/>
                  <Typography variant="body1" color="" align="justify">
                    <Box
                      component="img"
                      sx={{
                                   
                        maxHeight: { xs: 233, md:400 },
                        maxWidth: { xs: 350, md:500  },
                      }}
                      alt="Will you support a Child ?"
                      src={ind_promo}
                    />
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>
          
            <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PgIndia