import React from 'react'
import { Grid } from '@mui/material';
import { pg_cambodia, vt_promo } from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PageSubTitle from "../PageSubTitle";
import BulletPoints from '../BulletPoints';
import BarGraph from '../BarGraph';
import BarGraphPol from '../BarGraphPol';
import { PgCambodiaBulletPoints,  
cambodiaBarPoldata, cambodiaBarGraphData , cambodiaImgList } from "/react/mfa/src/dataset/cambodia";
import Link from '@mui/material/Link';
import ImageListApp from '../ImageListApp'

const PgCambodia = () => {
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF'}}>
          <img src={pg_cambodia} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='About Cambodia'/>
                  <Typography variant="body1" color="" align="justify">
                  Its mostly Khmer-speaking population endured many violent governmental changes in the last half of the 20th century. Invasions by its neighbors, the Vietnam War, and the ruthless Khmer Rouge regime have left Cambodians distrustful and guarded. The trauma from Pol Pot’s Khmer Rouge regime (1975-1979), its mass evacuation of cities and towns, and the ruthless genocide of those they viewed as opposition can be seen across all generations. One result is that approximately one-half of the population is under 21 and uneducated. The economy has also suffered from the turbulent past, and foreign aid accounts for one half of the central government budget.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  Since the 15th century, Buddhism has been the national religion in Cambodia. Today 85 percent of the population is Buddhist, while less than two percent is Evangelical Christian. In spite of past governmental attempts to eliminate all religions, Christianity has survived. Christians have worshiped openly since 1990, and indigenous church planters have established many new churches. The overwhelming social and emotional traumas of the past have left people open to the gospel. Mature Christian leadership is crucial to mobilize the churches to meet the needs of the people today.
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by Religion'/>
                  <BarGraphPol {...cambodiaBarPoldata}/>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/cambodia/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by People Group'/>
                  <Typography variant="body1" color="" align="justify">
                  <BarGraph {...cambodiaBarGraphData}/>
                  </Typography>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/cambodia/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>
          
            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='How can you pray for Cambodia ?'/>
                   <BulletPoints details={PgCambodiaBulletPoints} />
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='MFA Ministries in Cambodia'/>
                  <Typography variant="body1" color="" align="justify">
                    <ImageListApp imglist={cambodiaImgList}/>
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Will you support a Child ?'/>
                  <Typography variant="body1" color="" align="justify">
                    <Box
                      component="img"
                      sx={{
                          maxHeight: { xs: 233, md:400 },
                            maxWidth: { xs: 350, md:500  },
                          }}
                      alt="Will you support a Child ?"
                      src={vt_promo}
                    />
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>
          
            <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}



export default PgCambodia