import React, { useState } from "react";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab'

const ScrollTopButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrolled > 100) {
            setVisible(true);
        } else if (scrolled <= 100) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
            
        });
    };

    window.addEventListener("scroll", toggleVisible);

    return (
        
        <Fab size="medium" sx={{ color: '#008ca8' }} aria-label="Scroll Up"
            className="scrolltopButton" onClick={scrollToTop}
            style={{ display: visible ? "inline" : "none" }}>
                            <ArrowCircleUpIcon fontSize="large"/>
        </Fab>
    );
};

export default ScrollTopButton;