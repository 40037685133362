import React from 'react';
import { teamAus,underConstruction } from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import PageSubTitle from "../PageSubTitle";

const AboutTile = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#efefef' : '#efefef',
    ...theme.typography.h4,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: 'black',
  }));  
const MissionTeamNz = () => {
  return (
    <>
    <Grid sx={{ bgcolor: '#FFF'}}>
      <Grid>
        <Box sx={{ bgcolor: '#FFF' }}>
        <img src={underConstruction} alt="MFA" className='mfahomebanner'/>
        </Box>
      </Grid>
      <Grid container>
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">
            <PageSubTitle name='This page is under construction'/>
            <Typography variant="body1" color="" align="justify">
                By Sponsor a child, Sponsor a native missionary, Sponsor a young adult, Support Benovent Project.
            </Typography>
          </Container>
        </Box>

        
       
        
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
      </Grid>
    </Grid>
  </>
  )
}

export default MissionTeamNz