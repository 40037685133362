import React from 'react'
import { partnerOrganisation, gems, iem, 
} from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import PageSubTitle from "../PageSubTitle";
import {PostItlabel} from "C:/react/mfa/src/components";

const OurPartners = () => {
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF'}}>
          <img src={partnerOrganisation} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <PageSubTitle name='Our Partner Organisations'/>
                <Typography variant="body1" color="" align="justify">
                We work together with passionate overseas missions organisations to help their native missionary missions projects. We believe this is the most effective approach to accomplish MFA missions objectives, because they understand the heartbeat of their communities, and know the unique challenges and opportunities that exist in them. MFA role is not to tell them what to do, but to support their initiatives and help build their capacity. Sharing and working together as equals is what enables our projects to create ongoing change.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='The role of the church:'/>
                <Typography variant="body1" color="" align="justify">
                The church is the structure that God put in place for the growth of His people and the proclamation of His name to the nations, from generation to generation. We believe the local church is the one who supports mission projects and pray ministry works occurred in overseas. They are also involved in the spiritual care of the missionary though regular prayers behind the scenes as well as part of helping the missionary families to prepare for the mission field.
                </Typography>
              </Container>
          </Box>
     
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='The role of MFA:'/>
                <Typography variant="body1" color="" align="justify">
                The overseas missions partner organisation’s primary focus is to identify needs, train and prepare people for global great commission. Organisations such as MFA have voluntary ministry staff with experience and expertise connection with overseas missions partners to identify needs of missions projects in a cross-cultural context. We will help sponsors with getting proper ministry reports, missionary profiles, receipt of your financial contribution, provide local missions ministry opportunity to serve as a passionate cross-cultural local state missions ministry facilitator. We are here to equip and prepare people for mission trip and explore new unreached places in South east Asia regions. 
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Working in partnership:'/>
                <Typography variant="body1" color="" align="justify">
                The church and MFA organisation both play a role in caring for the mission worker while in their local native placement location. At MFA, we have people who understand some of the specific struggles faced by native missionary families and help with problem-solving, and provide ministry expertise and financial support.
                </Typography>
                <Typography variant="body1" color="" align="justify">
                The missionary project support church personally knows the mission worker on a deeper level and can care in different ways than we can as the mission organisation. We believe that partnership between the church and MFA is essential for the health and effective service of the mission worker.
                </Typography>
              </Container>
          </Box>
          
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">  
            <PageSubTitle name='Partner Organisations'/>
              <Box  sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {CPpartnerlist.map((partner) => (
                    <Grid container>
                      <Grid xs={2} sx={{ alignSelf : 'center'  }}></Grid>
                      <Grid xs={3} sx={{ alignSelf : 'center'  }}>
                        <Grid  sx={{ pt:1,pl:5 }}>
                          <img src={partner.partnerLogo} alt='partner logo' className="MFAPlogo"/>
                        </Grid>
                      </Grid>
                      <Grid xs={7}>
                        <Grid sx={{ pt:3 }}>
                          <Typography variant="h6" color="" align="justify">
                            {partner.partnerText}  
                           </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              </Container>
          </Box>

          <Grid container spacing={0} sx={{  height: 100}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const CPpartnerlist =[
    {
      partnerId: 'gems',
      partnerPath: '',
      partnerLogo: gems,
      partnerTitle: 'GEMS',
      partnerText: 'Gospel Echoing Missionary Society',
    },
    {
      partnerId: 'iem',
      partnerPath: '',
      partnerLogo: iem,
      partnerTitle: 'IEM',
      partnerText: 'Indian Evangelical Mission',
    },
    {
      partnerId: 'yfcsl',
      partnerPath: '',
      partnerLogo: '',
      partnerTitle: 'YFC',
      partnerText: 'Youth For Christ',
    },
    {
      partnerId: 'icf',
      partnerPath: '',
      partnerLogo: '',
      partnerTitle: 'ICF',
      partnerText: 'ICF Cambodia',
    }
  ];

  //Our Current local Church Partners
  const CPcountryList = [ 
    {
        mtext:'Gateway Gospel Church',
        mlocation: 'Melbourne',
        path: '/whereFocus'
    },
    {    
        mtext:'Corner Stone Church',
        mlocation: 'Sydney',
        path: '/whereFocus'
    }
  ];

export default OurPartners