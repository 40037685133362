import {preaching, sl_group1, sl_group2, sl_group3, sl_cgroup1, sl_cgroup2, sl_cgroup3, sl_gathering,} from '/react/mfa/src/img'

export const srilankaBarPoldata =[
    {
      populationDataSet : [
        {
          Buddhist: 70.2,
          Hindu: 12.6, 
          Muslim: 9.7, 
          Roman_Catholic: 6.1, 
          Christian: 1.3, 
          other: 0.05,
          Population: 'Religions',
        }
      ],
        populationDataSetLabels :  [
          { dataKey: 'Buddhist', label: 'Buddhist', stack: 'Religions' },
          { dataKey: 'Hindu', label: 'Hindu', stack: 'Religions' },
          { dataKey: 'Muslim', label: 'Muslim', stack: 'Religions' },
          { dataKey: 'Roman_Catholic', label: 'Roman Catholic', stack: 'Religions' },
          { dataKey: 'Christian', label:'Christian', stack: 'Religions' },
          { dataKey: 'other', label:'Other', stack: 'Religions', color : 'black'}
        ]
    }
  ];
  
  export const srilankaBarGraphData =[
    {
      peopleGroupDataSet : [
        {
          Sinhalese: 74.9, 
          SriLankan_Tamil: 11.2, 
          SriLankan_Moors: 9.2, 
          Indian_Tamil: 4.2, 
          other: 0.5,         
          religion: 'People Groups',
        }
      ],
      peopleGroupDataSetLabels: [
        { dataKey: 'Sinhalese', label: 'Sinhalese' },
        { dataKey: 'SriLankan_Tamil', label: 'Sri Lankan Tamil' },
        { dataKey: 'SriLankan_Moors', label: 'Sri Lankan Moors' },
        { dataKey: 'Indian_Tamil', label: 'Indian Tamil' },
        { dataKey: 'other', label: 'other' },
      ],
    }
  
  ]
  
  export const PgsrilankaBulletPoints =[
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for marginalised and vulnerable faith-based communities.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for churches facing challenges.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for the repeal of the Prevention of Terrorism Act (PTA).  ',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Pray for economic stability.',
    },
    
  ];
  
  export const srilankaImgList = [
    {
      img: preaching,
      title: "",
      author: "@sri lanka",
      rows: 2,
      cols: 2,
      featured: true
    },
    {
      img: sl_group1,
      title: "",
      author: "@sri lanka",
    },
    {
      img: sl_group2,
      title: "",
      author: "@sri lanka",
    },
    {
      img: sl_group3,
      title: "",
      author: "@sri lanka",
      cols: 2
    },
    {
      img: sl_cgroup1,
      title: "",
      author: "@sri lanka",
      rows: 2,
      cols: 2,
      featured: true
    },
    {
      img: sl_cgroup2,
      title: "",
      author: "@sri lanka",
    },
    {
      img: sl_cgroup3,
      title: "",
      author: "@sri lanka",
    },
    {
      img: sl_gathering,
      title: "",
      author: "@sri lanka",
    }
  ];