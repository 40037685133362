import React from 'react'
import { Grid } from '@mui/material';
import { connectInPrayer } from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { icon_nsw,
    icon_vic,
    icon_sa,
    icon_wa } from "/react/mfa/src/img";
import PageSubTitle from "../PageSubTitle";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const GridBoxCnt = styled(Paper)(({ theme }) => ({
  container: {
    border: '4px solid green',
    display: 'inline-flex',
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#efefef' : '#efefef',
  ...theme.typography.h5,
  padding: theme.spacing(0),
  textAlign: 'left',
  color: '#1e8a84',
  border: '4px solid green',
}));

const ConnectInPrayer = () => {
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF' }}>
          <img src={connectInPrayer} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Join with Us'/>
                <Typography variant="body1" color="" align="justify">
                    Missions Fellowship Australia has always been committed to prayer and seeking God. We do this as individuals, as ministry locations, as countries and at many other levels. One of MFA’s State level prayer initiatives is a monthly prayer gathering called “<b>Missions Prayer Fellowship</b>”.
                    <br></br><br></br>
                    This prayer points from Overseas missions partners on monthly basis in respective ministry streamlines focuses on day to day ministry challenges / needs/ Spiritual Supports. We also engage missionaries and mission’s partners through ZOOM channel to share the testimonies.  
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Location and Time'/>
              {statePrayer.map((stateTeam) => (
              <Grid container spacing={1} sx={{ pt:3 , borderRadius: 25}}>
                <>
                <Grid item sx={{ display: 'flex', justifyContent: 'center'}}>
                  <img src={stateTeam.img} alt={stateTeam.author} height='200'/>
                </Grid>
                <Grid item sx={{  justifyContent: 'center',alignItems:'center'}} >
                    <Grid xs={12}>
                    <br></br>
                    <Typography variant="body1" color="" align="justify">
                    {stateTeam.content}
                    </Typography>
                    <Typography variant="body1" color="" align="justify">
                    {stateTeam.location}
                    </Typography>
                    <Typography variant="body1" color="" align="justify">
                    {stateTeam.contact}
                    </Typography>
                    </Grid>
                </Grid>
                </>
              </Grid>
              ))}
              <br></br>
              <Typography variant="body1" color="#1e8a84" align="justify">
              <i><b>Note: </b>The day and time of the prayer fellowships is subject to change and you will be notified by 
              the respective state members closer to the date.</i>
              </Typography>
              
            </Container>
          </Box>
          
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='How to Participate?'/>
              <Typography variant="body1" color="" align="justify">
              Reach out to your local state coordinator by contacting them. Alternatively, to receive emails about missions updates and prayer points, 
                <br></br>
                Please sign up for our newsletter here at the bottom of this page. 
                </Typography>
            </Container>
          </Box>
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const statePrayer = [
    {
      img: icon_nsw,
      title: 'New South Wales',
      author: '@sydney',
      path: '',
      content: 'Monthly second Saturday, 6:30 pm AEST.',
      location: '',
      contact: ' Please call 0401 402 861 for more info.',
    },
    {
      img: icon_vic,
      title: 'Victoria',
      author: '@melbourne', 
      path: '',
      content: 'Monthly fourth Saturday, 6: 30 pm AEST. ',
      location: 'Location: 25/27 James Street, Dandenong, VIC-3175.',
      contact: 'Please call 0432 552 332 for more info.',
    },
    {
      img: icon_sa,
      title: 'South Australia',
      author: '@adelaide',     
      path: '',
      content: 'Monthly first Saturday, 5:00 pm ACST.',
      location: 'Location: 11 Broad Street, Queenstown, Adelaide, SA-5014.',
      contact: 'Please call 0434 508 345 for more info.',
    },
    {
      img: icon_wa,
      title: 'Western Australia',
      author: '@perth',   
      path: '',
      content: 'Monthly second Friday, 7:00 pm AWST. Location:TBC',   
      location: '',
      contact: '',
    },
   
  ];

export default ConnectInPrayer