import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { tile_MFA, tile_MFAP } from "C:/react/mfa/src/img";
import { useNavigate } from 'react-router-dom';
   
const MFAtiles = () => {
  const navigate = useNavigate()
  return (
    <>
      <Container maxWidth="md">
        <Grid sx={{ flexGrow: 1 }} container spacing={1}>
          <Grid item xs={12}>
            <Grid container item justifyContent="center" spacing={0} xs={12}>
              {CityTile.map((tiles) => (
                <Button key={tiles.id} size="small" onClick={() => {
                    navigate(tiles.path)
                    }}>
                    <Card  sx={{ p:0, m:0, maxWidth: 385 ,boxShadow: '0 2px 14px 0 rgb(32 40 45 / 15%)', 
                    ':hover': {
                        boxShadow : '0 2px 14px 0 rgb(32 40 45 / 60%)' ,
                      }}} >
                        <CardMedia
                        component="img"
                        alt={tiles.title}
                        height={tiles.height}
                        image={tiles.img}
                        />
                    </Card>
                </Button>
              ))}
            </Grid> 
          </Grid>
        </Grid>
      </Container> 
    </>
  );
}

const CityTile = [
  {
    img: tile_MFA,
    id: 'CityTileMFA',
    title: 'Missions Fellowship Australia',
    height: 225,
    path: '/aboutMFA',
    rows: 1,
    cols: 1,
    
  },
  {
    img: tile_MFAP,
    id: 'CityTileMFAP',
    title: 'Missions Fellowship Asia Pacific',
    height: 225,
    path: '/aboutMFAP',
    cols: 2,
  }
 
];

export default MFAtiles