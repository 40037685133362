import {computerclass, my_group1, my_group2, my_group3, my_cgroup1, my_cgroup2, my_cgroup3, my_gathering} from '/react/mfa/src/img'

export const myanmarBarPoldata =[
    {
      populationDataSet : [
        {
          Buddhist: 87.9,
          Christian: 6.2,
          Muslim: 4.3,
          Animist: 0.8,
          Hindu : 0.5,
          Other : 0.2,
          none: 0.1,
          Population: 'Religions',
        }
      ],
        populationDataSetLabels :  [
          { dataKey: 'Buddhist', label: 'Buddhist', stack: 'Religions' },
          { dataKey: 'Christian', label:'Christian', stack: 'Religions' },
          { dataKey: 'Muslim', label: 'Muslim', stack: 'Religions' },
          { dataKey: 'Animist', label: 'Animist', stack: 'Religions' },
          { dataKey: 'Hindu', label: 'Hindu', stack: 'Religions' },
          { dataKey: 'Other', label:'Other', stack: 'Religions', color : 'black'},
          { dataKey: 'none', label:'none', stack: 'Religions' },
        ]
    }
  ];
  
  export const myanmarBarGraphData =[
    {
      peopleGroupDataSet : [
        {
          Burman : 68,
          Shan : 9,
          Karen : 7,
          Rakhine : 4,
          Chinese : 3,
          Indian : 2,
          Mon : 2,
          other : 5,
          religion: 'People Groups',
        }
      ],
      peopleGroupDataSetLabels: [
        { dataKey: 'Burman', label: 'Burman' },
        { dataKey: 'Shan', label: 'Shan' },
        { dataKey: 'Karen', label: 'Karen' },
        { dataKey: 'Rakhine', label: 'Rakhine' },
        { dataKey: 'Chinese', label: 'Chinese' },
        { dataKey: 'Indian', label: 'Indian' },
        { dataKey: 'Mon', label: 'Mon' },
        { dataKey: 'other', label: 'other' },
      ],
    }
  
  ]
  
  export const PgmyanmarBulletPoints =[
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: 'Please pray that the Holy Spirit will comfort, sustain and empower believers amid the ongoing conflict and the threat of persecution.',
    },
    {
      bulletID: '',
      icon: 'Check_Circle',
      text: "Pray for the protection of churches and Christians, and that believers will be given wisdom as they respond to different challenges.",
    },
    {
        bulletID: '',
        icon: 'Check_Circle',
        text: "Ask for an end to the civil war. Pray that the ruling military will do more to bring justice and freedom for all.",
      },
      
   
  ];
  
  
  export const myanmarImgList = [
    {
      img: my_gathering,
      title: "",
      author: "@myanmar",
      rows: 2,
      cols: 2,
      featured: true
    },
    {
      img: my_cgroup3,
      title: "",
      author: "@myanmar",
    },
    {
      img: my_cgroup2,
      title: "",
      author: "@myanmar",
    },
    {
      img: my_cgroup1,
      title: "",
      author: "@myanmar",
      cols: 2
    },
    {
      img: my_group3,
      title: "",
      author: "@myanmar",
      rows: 2,
      cols: 2,
      featured: true
    },
    {
      img: my_group2,
      title: "",
      author: "@myanmar",
    },
    {
      img: my_group1,
      title: "",
      author: "@myanmar",
    },
    {
      img: computerclass,
      title: "",
      author: "@myanmar"
    }
  ];