import React, { useEffect, useState} from 'react';
import { Grid } from '@mui/material';
import { underConstruction, MFALogo , dgr} from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import InboxIcon from '@mui/icons-material/Inbox';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

function Copyright() {
    return (
      <Typography variant="body2" color="white" align="center">
        {'Copyright © '}
        
          Missions Fellowship Website
        {' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  const FooterButton = styled(Button)(({ theme }) => ({
    color: 'white',
    padding: '4px 5px',
    fontSize: '0.8rem',
    backgroundColor: 'transparent',
    border: 'none',
    '&:hover': {
      fontWeight:'Bold', color:'white', borderBlockColor:'white',
        border: '1px solid white',
    },
  }));

const MFAlaunch = () => {

  const targetDate = new Date("2024-12-07T23:59:59");
  const timeLefttoLaunch =  targetDate - new Date();
  
  const [time, setTime] = useState(timeLefttoLaunch);
    useEffect(() => {
        setTimeout(() => {
            setTime(time - 1000);
        },1000);
     },[time])

    const getFormattedTime = (milliseconds) => {
        let total_seconds = parseInt(Math.floor(milliseconds / 1000));
        let total_minutes = parseInt(Math.floor(total_seconds / 60));
        let total_hours = parseInt(Math.floor(total_minutes / 60));
        let days = parseInt(Math.floor(total_hours / 24));

        let seconds = parseInt(total_seconds % 60);
        let minutes = parseInt(total_minutes % 60);
        let hours = parseInt(total_hours % 24);

        //return `${days}: ${hours}: ${minutes}: ${seconds}`;
        return(<>
          <Box  sx={{ display:'contents', alignContent:'center', justifyContent: 'center'}}>
            <Grid container sx={{display:'flex', alignContent:'center'}}>
            <Grid item xs={1} md={2} sx={{}}></Grid>
            <Grid item xs={2} md={1} sx={{border: '1px solid #008ca8', borderRadius:3}}>
              <Typography variant="h3" color='#008ca8' align="center">{days}</Typography>
              <Typography variant="body2" color='#aa2149' align="center">days</Typography>
            </Grid>
            <Grid item xs={0} md={1}>
            <Typography variant="h3" color='#008ca8' align="center">:</Typography>
            </Grid>
            <Grid item xs={2} md={1} sx={{border: '1px solid #008ca8', borderRadius:3}}>
              <Typography variant="h3" color='#008ca8' align="center">{hours}</Typography>
              <Typography variant="body2" color='#aa2149' align="center">hours</Typography>
            </Grid>
            <Grid item xs={0} md={1}>
            <Typography variant="h3" color='#008ca8' align="center">:</Typography>
            </Grid>
            <Grid item xs={2} md={1} sx={{border: '1px solid #008ca8', borderRadius:3}}>
              <Typography variant="h3" color='#008ca8' align="center">{minutes}</Typography>
              <Typography variant="body2" color='#aa2149' align="center">minutes</Typography>
            </Grid>
            <Grid item xs={0} md={1}>
            <Typography variant="h3" color='#008ca8' align="center">:</Typography>
            </Grid>
            <Grid item xs={2} md={1} sx={{border: '1px solid #008ca8', borderRadius:3}}>
              <Typography variant="h3" color='#008ca8' align="center">{seconds}</Typography>
              <Typography variant="body2" color='#aa2149' align="center">seconds</Typography>
            </Grid>
            <Grid item xs={0} md={2}></Grid>
            </Grid>         
          </Box>
        </>)
    }
    const navigate = useNavigate()
    return (
    

    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
      <nav>
            <Grid className='ribbionMenu'>
                <Grid  item xs={3} md={3} lg={2}>
                    <Grid>
                        <img src={MFALogo} alt="Missions Fellowship Australia" className="mfalogo" />
                    </Grid>
                </Grid>
                <Grid container item xs={6} md={6} lg={8} >
                    <Grid item xs={12} md={12} lg={12} className='div-padding'>
                        <Grid>
                            <Link  className='title'>Missions Fellowship Australia</Link>
                        </Grid>
                    </Grid>
                    
                </Grid>
                <Grid item  xs={3} md={3} lg={2}>
                
                </Grid>
            </Grid>
        </nav>
        <nav>
            <Grid className='mobileMenu'>
                <Grid container>
                    
                    <Grid item xs={12} md={12} lg={8} >
                        <Grid sx={{color:'#008ca8'}}>
                            <Link  className='shorttitle'>Missions Fellowship Australia</Link>
                        </Grid>
                    </Grid>
                    
                </Grid>
                
                    
                
                
                
            </Grid>
        </nav>
        <Grid sx={{display:'contents', alignContent:'center'}}>
                <Box  sx={{ display:'contents', alignContent:'center', justifyContent: 'center'}}>
                    
                    
                            <Typography variant="h2" color='#008ca8' align="center">
                            {getFormattedTime(time)}
                            </Typography>
                    
                        
                    
                </Box>         
          
          </Grid>
          <Grid  container spacing={0} sx={{  height: 30}}></Grid>
        <Grid container sx={{display: 'flex'}}>
        <Grid md={6}>
          <Box sx={{ bgcolor: '#FFF' }}>
          <img src={underConstruction} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid md={6}><Box  sx={{ flexGrow: 1 , backgroundColor:'#008ca8'}}>
              <Container maxWidth="md">
                <Typography variant="body1" color="White" align="justify">
                <br></br><b>Dear Sponsors / Prayer Partners / and Missions Organizations,</b>
                <br></br>Praise the Lord! Greetings in the name of Lord Jesus!
<br></br>
                <br></br>We are excited to announce that Missions Fellowship Australia is about to release entire new look website which would become easier and more convenient than ever!
We've been working tirelessly behind the scenes to bring you an extraordinary Sponsor’s online experience. Our website is getting a complete makeover, and we can't wait to unveil the surprises we have in relaunch.
<br></br>
                <br></br>While we don't want to give too much away just yet, we promise it'll be worth the wait. Prepare to be amazed by a fresh design, innovative features, and an unparalleled user journey.
Stay tuned for more updates and be sure to mark your calendar 7th December – 2024 – the countdown to greatness has begun!

<br></br>              <br></br> 
                <b>Sincerely,
                <br></br>MFA IT & Media Team</b>
                </Typography>
              </Container>
              <Grid  container spacing={0} sx={{  height: 20}}></Grid>
              <Grid container>
                <Grid item xs={9} sx={{ align: 'center'}}> 
                    <Copyright />
                </Grid>
                <Grid item xs={3} sx={{ align: 'center'}}> 
                    <Typography variant="body2" align="left" gutterBottom color="white">
                        <FooterButton startIcon={<InboxIcon/>} onClick={() => {
                                navigate('props.details.path')
                            }}>
                                <Link to="route" target="_blank" rel="noopener noreferrer" underline="none"
                    href="https://mbox.s452.sureserver.com/?domain=mfa.org.au" sx={{ color: 'inherit'}}
                >Webmail</Link></FooterButton>
                        </Typography>
                </Grid>
            </Grid>
          </Box></Grid>
          
          
          
        </Grid>
      </Grid>
    </>
  )
}

export default MFAlaunch