import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import {useState } from 'react';
  
function VTSteps(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
       
    const tempstep = props.details.find((tstep) => activeStep === tstep.id);
   
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
   
    return (
      <Box sx={{ width: '100%' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {props.details.map((label, index) => (
            <Step key={label.id} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label.name}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 ,justifyContent: 'center'}}>
              <img src={tempstep.img} alt="MFA" height='250' width='385'/>
            </Box>
          </React.Fragment>
        </div>
      </Box>
    );
  }
  

export default VTSteps;