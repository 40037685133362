import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import { Grid } from '@mui/material';

import Box from '@mui/material/Box';


import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

    import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { icon_nsw,
    icon_vic,
    icon_sa,
    icon_wa } from "C:/react/mfa/src/img";
    import { useNavigate } from 'react-router-dom';
       
    const AboutTile = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
      ...theme.typography.h4,
      padding: theme.spacing(0),
      textAlign: 'left',
      color: 'black',
    }));  
    

const StateMissionsTeam = () => {
  const navigate = useNavigate()
  return (
    <>
      

          
          <Container maxWidth="md">
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
                <AboutTile>
                <b>Join with our State Missions Team</b>
                </AboutTile>
                <br></br>
                <Typography variant="h6" color="" align="justify">
                   
                   
                </Typography>
              </Container>
          </Box>
            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
              <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={1}>
                  {CityTile.map((tiles) => (
                  <Card  sx={{ p:3, m:2, maxWidth: 345 ,boxShadow: '0 2px 14px 0 rgb(32 40 45 / 15%)', ':hover': {
                    boxShadow : '0 2px 14px 0 rgb(32 40 45 / 60%)' ,
                  }}}  className=''>
                      <CardMedia
                      component="img"
                      alt="Connect Through by Giving"
                      height="140"
                      image={tiles.img}
                      />
                      <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                      {tiles.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                      
                      </Typography>
                      </CardContent>
                      <CardActions>
                      
                      <Button size="small" onClick={() => {
                        navigate(tiles.path)
                      }}>Learn More</Button>
                      </CardActions>
                  </Card>
                ))}
                  </Grid>
              </Grid>
          </Grid>
        </Container> 
    </>
  );
}

const CityTile = [
  {
    img: icon_nsw,
    title: 'New South Wales',
    author: '@sydney',
    path: '/MissionsTeamNsw',
    rows: 1,
    cols: 1,
    
  },
  {
    img: icon_vic,
    title: 'Victoria',
    author: '@melbourne',
    path: '/MissionsTeamVic',
    cols: 2,
  },
  {
    img: icon_sa,
    title: 'South Australia',
    path: '/MissionsTeamSa',
    author: '@adelaide',
    cols: 3,
  },
  {
    img: icon_wa,
    title: 'Western Australia',
    path: '/MissionsTeamWa',
    author: '@perth',
    cols: 4,
  },
 
];


export default StateMissionsTeam