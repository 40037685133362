import React from 'react'
import { missionsPartner, gems, iem} from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PageSubTitle from "../PageSubTitle";
import BulletPoints from "../BulletPoints";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const MissionsPartner = () => {
  
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF' }}>
          <img src={missionsPartner} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Church and Missions Organisations'/>
                <Typography variant="body1" color="" align="justify">
                A great way for your church to get involved with MFA is by 
supporting one or more specific missionaries. If you're interested or would like more 
information, please reach out to your local state missions’team.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Prayer'/>
                <Typography variant="body1" color="" align="justify">
                Encourage your church to pray for your sponsored ministry, be it
individually, in small groups, youth groups, Sunday school or as part of the weekly 
service.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Care'/>
                <Typography variant="body1" color="" align="justify">
                Care for your sponsored missionaries while they are on location and when they return to Australia for Sponsor or missions connect assignments. There are plenty of ways to make them feel part of your church community. Here are some ways you can support them:
                <br></br>
                  <br></br>
                  <Typography variant="body1" color="#1e8a84" align="justify">
                    <b>While they are on location:</b></Typography>
                    <br></br>
                  <BulletPoints details={MissionCare1BulletPoints}/>
                  <br></br>
                  <Typography variant="body1" color="#1e8a84" align="justify">
                    <b>While they are visit Australia for short term missions connect program:</b>
                  </Typography>
                  <br></br>
                  <BulletPoints details={MissionCare2BulletPoints}/>
                  
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Church Visits'/>
                <Typography variant="body1" color="" align="justify">
                While connected with MFA, we will visit your church to share 
about your supported ministry. This is a great opportunity to inspire excitement about 
cross-cultural mission and encourage your congregation to recommit to another year 
of support. Make the most of your local state missions team coordinator's visit by inviting them to speak at your small groups, Sunday school class, youth group, and 
church services.
                </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Go on a Missions Trip'/>
                <Typography variant="body1" color="" align="justify">
                    Could your church organize a short-term mission trip to visit your supported ministry and help with a specific ministry they do? Talk with your local state mission coordinator to see if this would be a possibility. 
                    <br></br>
                    A short-term trip to visit your supported missionary or child is a great opportunity to see what their life and ministry looks like and be encouraged to continue supporting them.
                </Typography>
              </Container>
          </Box>

         

          

          <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const MPpartnerlist =[
  {
    partnerId: 'gems',
    partnerPath: '',
    partnerLogo: gems,
    partnerTitle: 'GEMS',
    partnerText: 'Gospel Echoing Missionary Society',
  },
  {
    partnerId: 'iem',
    partnerPath: '',
    partnerLogo: iem,
    partnerTitle: 'IEM',
    partnerText: 'Indian Evangelical Mission',
  },
  {
    partnerId: 'yfcsl',
    partnerPath: '',
    partnerLogo: '',
    partnerTitle: 'YFC',
    partnerText: 'Youth For Christ, Sri Lanka',
  },
  {
    partnerId: 'pmm',
    partnerPath: '',
    partnerLogo: '',
    partnerTitle: 'Pennial Missions',
    partnerText: 'Pennial Missions, Myanmar',
  },
  {
    partnerId: 'bym',
    partnerPath: '',
    partnerLogo: '',
    partnerTitle: 'Blessing youth Missions',
    partnerText: 'Blessing youth Missions',
  },
  {
    partnerId: 'icfm',
    partnerPath: '',
    partnerLogo: '',
    partnerTitle: 'ICF Missions',
    partnerText: 'ICF Missions, Cambodia',
  }
];


const MissionCare1BulletPoints =[
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Be sure to ask what they miss and what is appropriate for their location.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Send a care package with items they may not have access to.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Send an email to let them know you’ve been praying for them.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Create a video message from your church or small group to show your support and encouragement.',
  }
  
];

const MissionCare2BulletPoints =[
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Offer practical help by lending them a house, car, or holiday home during their stay.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Reach out to your local branch to learn about any specific needs they might have.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Invite them to join your church programs or assist them share the testimonies.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_Checked',
    text: 'Treat your sponsored missionary to a meal or invite them for an outing to help them feel welcome.',
  },
  
];

export default MissionsPartner;