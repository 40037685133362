import React from 'react'
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { tile_ChurchPlanting, tile_VillageTransformation, tile_LifeTransformation,
  tile_MercyMinistry, tile_PrayerPartner
 } from "C:/react/mfa/src/img";
import { useState } from "react";
import MinistryLinks from 'C:/react/mfa/src/components/MinistryLinks';
import { MenuItems} from 'C:/react/mfa/src/components/InputData';
import { FinanicalPolicy, MinistryCard, MFAtiles, 
  
} from "C:/react/mfa/src/components";
import MFACarousel from './MFACarousel';
import PageSubTitle from "../PageSubTitle";

const HPItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  boxShadow: 'none',
  
}));

const HomePage = () => {
  const [activeMenucm, setActiveMenucm] = useState("ourMinistries");
  const targetMenucm = MenuItems.find((article) => activeMenucm === article.mid);
  return (
    <>
      <Grid sx={{ bgcolor: '#ffffff'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF'}}>
          {/* <img src={homepage} alt="MFA" className='mfahomebanner'/> */}
          <MFACarousel></MFACarousel>
          </Box>
        </Grid>
        <Grid container>          
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <MFAtiles></MFAtiles>
            </Grid>
          </Box>
          
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Ministries'/>
                <Typography variant="h6" color="" align="justify">
                   
                </Typography>
              </Container>
          </Box>
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Grid container spacing={0} sx={{ flexGrow: 1 ,justifyContent:'center', }}> 
              {ministryTiles.map(submitem => {
                return (
                    <Grid key={submitem.mtid}><MinistryCard details={submitem}></MinistryCard></Grid>
                  );
                      
                })}
            </Grid>
          </Box>
          
          

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Our financial policy'/>
                <Typography variant="h6" color="" align="justify">
                   
                </Typography>
                <Box  sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
              
              <Grid item xs={12}>
                <HPItem>
                  <FinanicalPolicy></FinanicalPolicy> 
                </HPItem>
              </Grid>
              
              
            </Grid>
          </Box>
              </Container>
          </Box>

          

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          

            <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const ministryTiles = [ 
  {    
      mtid: 'mtConnectInPrayer',
      mtextTitle: 'Be A Prayer Partner',
      path: '/ConnectInPrayer',
      pic: tile_PrayerPartner,
      desc: 'Provide prayer support for ministry workers and people who are seeking our prayers. ',
      shortdesc1: 'Pray with us',
      mtext: 'Prayer Partner',
      link: ''
  },
  {
      mtid: 'mtChurchPlanting',  
      mtextTitle: 'Sponsor A Missionary',
      path: '/ChurchPlanting',
      pic: tile_ChurchPlanting,
      desc: 'Equip and send a native missionary to a pioneer village to share the Good News of Jesus Christ.',
      shortdesc1: 'Sponsor a native missionary',
      mtext: 'Church Planting',
      link: ''
  },
  {    
      mtid: 'mtVillageTransformation',
      textTitle: 'Sponsor A Child',
      path: '/VillageTransformation',
      pic: tile_VillageTransformation,
      desc: 'Ensure each child receives tailored, holistic care to help them break free from poverty.',
      shortdesc1: 'Sponsor a child at risk',
      mtext: 'Village Transformation',
      link: ''
  },
  {    
      mtid: 'mtLifeTransformation',
      mtextTitle: 'Sponsor A Young Adult',
      path: '/LifeTransformation',
      pic: tile_LifeTransformation,
      desc: 'Provide counselling, emergency medical care and provide further education and skills-training to young adults.',
      shortdesc1: 'Support a young adult transformation program',
      mtext: 'Life Transformation',
      link: ''
  },
  {    
      mtid: 'mtMercyMinistry',  
      mtextTitle: 'Sponsor  A Benevolent Project',
      path: '/MercyMinistry',
      pic: tile_MercyMinistry,
      desc: 'Provide financial support and professional advice to disadvantaged young people and families to build economically self-supporting.',
      shortdesc1: 'Support a benevolent relief project',
      mtext: 'Mercy Ministry',
      link: ''
  }
];

export default HomePage