import React from 'react'
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { missionVision} from "C:/react/mfa/src/img";
import {Policy} from "C:/react/mfa/src/components";
import PageSubTitle from "../PageSubTitle";
import { FinanicalPolicy, InPartnership, LongTerm, Reachtheunreached,} from 'C:/react/mfa/src/img';

const MnVItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#FFF' : '#FFF',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  
  textAlign: 'center',
  boxShadow: 'none',
}));

const MissionAndVision = () => {
  return (
    
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF'}}>
          <img src={missionVision} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
              <PageSubTitle name='Our Mission'/>
              <Typography variant="body1" color="" align="justify">
                Equipping native missionaries to serve effectively in their own community.
                Identify holistic needs of the people residing in the community and systematically address needs in
                a tangible manner.
              </Typography>
              </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Our Vision'/>
              <Typography variant="body1" color="" align="justify">
                Reaching across nations with the Good News of our Lord Jesus Christ, enabling people to reach out and bring transformation within their communities.
              </Typography>
            </Container>
          </Box>
          
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md" >  
            <PageSubTitle name='Our Policy'/>
              
                <Grid container spacing={0} sx={{ flexGrow: 1 , justifyContent: 'center'}}>
                  {Policylist.map((policy) => (
                       <Policy key={policy.policyId} details={policy}/>
                  ))}
                </Grid>
              
              </Container>
          </Box>
         
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const Policylist =[
  {
      policyId: 'Reachtheunreached',
      policyPath: '',
      policyImg: Reachtheunreached,
      policyTitle: 'Reach the Unreached ',
      policyText:'We proclaim the gospel through native indigenous people.',
  },
  {
    policyId: 'LongTerm',
    policyPath: '',
    policyImg: LongTerm,
    policyTitle: 'Long-Term',
    policyText:'We commit long-term projects to developing cultural understanding and complete transformation.',
  },
  {
    policyId: 'InPartnership',
    policyPath: '',
    policyImg: InPartnership,
    policyTitle: 'In Partnership',
    policyText:'We partner with overseas missions organisations under authority of local governance. ',
  },
  {
    policyId: 'FinanicalPolicy',
    policyPath: '',
    policyImg: FinanicalPolicy,
    policyTitle: 'Financial Policy',
    policyText:'We commit to send 100% funds to beneficiary without admin cost.   ',
  }
];

export default MissionAndVision