import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const sideTile = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#f9bc99' : '#f9bc99',
    ...theme.typography.h4,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: 'black',
  })); 

const SectionTitle = (props) => {
  return (
    <div>
        <sideTile><typography variant="h6">{props.tilename}</typography></sideTile>
        <br></br>
    </div>
  )
}

export default SectionTitle