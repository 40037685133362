import React from 'react';
import { faqs } from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import PageSubTitle from "../PageSubTitle";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BulletPoints from "../BulletPoints";
 

const FAQs = () => {

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF' }}>
          <img src={faqs} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
              <PageSubTitle name='Frequently asked questions'/>
              <div>
                {faqPanelContent.map(panelItem => {
                  return (
                    <>
                      <Accordion expanded={expanded === panelItem.panelValue} onChange={handleChange(panelItem.panelValue)}>
                        <AccordionSummary sx={{border:' solid 1px' ,borderColor: '#1e8a84'}}
                          expandIcon={<ExpandMoreIcon  sx={{ color: 'red' }}/>}
                          aria-controls={panelItem.panelControls}
                          id={panelItem.panelId}
                        >
                          <Typography >
                            {panelItem.panelQuestion}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx ={{background:'#1e8a84'}}>
                          <Typography variant="body1" color="white" align="justify" >
                          <b>Ans: </b>{panelItem.panelAnswer}
                          </Typography>
                          <Typography variant="body1" color="white" align="justify" >
                             {panelItem.panelBullets}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                })}
              </div>
            </Container>
          </Box>
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const FaqsServeBulletPointsPanel4 =[
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: 'Donations through Missions Fellowship Asia Pacific are tax deductible.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: '100% of your financial contribution is sent to the missions field.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: "No administration cost taken from sponsor's contribution.",
  },
  
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: "Sponsor's fund used only for specific projects.",
  },
  
];


const FaqsServeBulletPointsPanel5 =[
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: 'Lead and organise monthly fellowship meeting and pray for the missionaries who are working in the field.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: 'Collaborate with local believers for prayers and sponsorship engagements.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: 'Conduct annual missions connect programs in your respective states.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: 'Be a State Missions team member, build a team and develop ministry strategies locally.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: 'Visit and organise missions trips to MFA supported missions projects.',
  },
  
];

const FaqsServeBulletPointsPanel7 =[
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: 'Connect to your local state monthly missions prayer fellowship meetings.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: 'Contact your local state coordinator by email or phone.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: ' You will be notified on regular ministry updates through your local state coordinators through WhatsApp messages.',
  },
  {
    bulletID: '',
    icon: 'Radio_Button_CheckedWhite',
    text: 'You will receive the Monthly Prayer Plus newsletter through email.',
  },
  
];

const faqPanelContent =[
  {
    panelId: 'panel1bh-header',
    panelValue: 'panel1',
    panelControls: 'panel1bh-content',
    panelQuestion: 'Does MFA take any administrative cost from sponsor’s giving ?',
    panelAnswer:'No. Administrative costs are covered by members of MFA.',
  },
  {
    panelId: 'panel2bh-header',
    panelValue: 'panel2',
    panelControls: 'panel2bh-content',
    panelQuestion: 'Does MFA take money from sponsor’s giving for overseas mission trips and travel ?',
    panelAnswer:'No. All travel expenses are covered by individuals who are travelling on mission trips.',
  },
  {
    panelId: 'panel3bh-header',
    panelValue: 'panel3',
    panelControls: 'panel3bh-content',
    panelQuestion: "How does MFA meet operational and administrative costs ? ",
    panelAnswer:'Ministry coordinators pledge yearly contribution for the operational and administrative expenses of MFA. Operational and administration costs are covered by membership subscription and volunteer contributions by its members. ',
  },
  {
    panelId: 'panel4bh-header',
    panelValue: 'panel4',
    panelControls: 'panel4bh-content',
    panelQuestion: "What is MFA's financial policy ?",
    panelAnswer:'Our financial policy',
    panelBullets:  <BulletPoints details={FaqsServeBulletPointsPanel4}/> ,
  },
  {
    panelId: 'panel5bh-header',
    panelValue: 'panel5',
    panelControls: 'panel5bh-content',
    panelQuestion: "How can I serve the Lord through MFA?",
    panelAnswer:  'You can be a State Missions team member to serve the Lord in following areas:' ,
    panelBullets:  <BulletPoints details={FaqsServeBulletPointsPanel5}/> ,
  },
  {
    panelId: 'panel6bh-header',
    panelValue: 'panel6',
    panelControls: 'panel6bh-content',
    panelQuestion: "How can organisations and churches join with MFA and spread the good news of Jesus and care for people in need? ",
    panelAnswer:'Be a prayer and financial partner to support the great commission of the Lord. Our MFA State Missions Team coordinator may have a ministry presentation at your place to convey the latest missions movements.',
  },
  {
    panelId: 'panel7bh-header',
    panelValue: 'panel7',
    panelControls: 'panel7bh-content',
    panelQuestion: "How does MFA communicate with sponsors about progress of overseas and local mission’s activity?",
    panelAnswer:'MFA has various communication channels to convey our missions activities as stated.',
    panelBullets:  <BulletPoints details={FaqsServeBulletPointsPanel7}/> ,
  },
];

export default FAQs