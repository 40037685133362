import React from 'react'
import { Grid } from '@mui/material';
import { connectThroughGiving } from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { MenuItems} from '../InputData';
import MinistryCardMini from "../MinistryCardMini";
import Stack from '@mui/material/Stack';
import PageSubTitle from "../PageSubTitle";
import BulletPoints from "../BulletPoints";

const ConnectThroughGiving = () => {
  const [activeMenucm, setActiveMenucm] = useState("ourMinistries");
  const targetMenucm = MenuItems.find((article) => activeMenucm === article.mid);
  
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF' }}>
          <img src={connectThroughGiving} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
              <Container maxWidth="md">
              <PageSubTitle name='Giving to MFA'/>
                <Typography variant="body1" color="" align="justify">
                  
                  <BulletPoints details={connectThroughGivingBulletPoints}/>
                </Typography>
                <br></br>
              </Container>
              <Box  sx={{ flexGrow: 1 }}>
              <Grid container spacing={3} sx={{ flexGrow: 1 ,justifyContent:'center', }}>
                        {targetMenucm.mitems.map(submitem => {
                        return (
                            <Grid item ><MinistryCardMini details={submitem}></MinistryCardMini></Grid>
                        );
                            
                        })}
                    </Grid>
                </Box>
          </Box>

         
          
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Direct Debit'/>
              <Typography variant="body1" color="" align="justify">
              Giving via direct debit is a convenient and simple way to give regularly to MFA. It also saves money on bank fees. Contact your local branch to find out more. 
                </Typography>
            </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Giving a Tax Deductible Donation to MFAP'/>
              <Typography variant="body1" color="" align="justify">
              For Australian taxpayers, donations given to Missions Fellowship Asiapacific ministries such as, sponsor a child at risk, support a young adult transformation, support a benevolent relief project are tax deductible. The tax receipt will be issued at the end of each financial year. 
                </Typography>
            </Container>
          </Box>

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Contact Us'/>
              <Typography variant="body1" color="" align="justify">
                  If you have any questions regarding your donation to MFA or enquiries about your registration ID, 
                  please feel free to contact our finance services desk at: <u>finance@mfa.org.au</u> <br></br>

                  Thank you for your generosity!
                </Typography>
            </Container>
          </Box>

          <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

const connectThroughGivingBulletPoints =[
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'Your financial gifts help us spread the good news of Jesus and care for people in need. We are grateful to God for your help!',
  },
  {
    bulletID: '',
    icon: 'Check_Circle',
    text: 'To give to MFA supported specific overseas Ministry Projects please click on the relevant tile below',
  },
];

export default ConnectThroughGiving