import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';



const EItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: 25,
}));


 const Events = (props) => {
    
  return (
    <>
        <Card EItem sx={{ bgcolor: '#1d92df', maxWidth: 345 , maxHeight: 310
        ,boxShadow: '0 2px 14px 0 rgb(32 40 45 / 15%)', ':hover': {
                    boxShadow : '0 2px 14px 0 rgb(32 40 45 / 100%)' ,
                  }
                  }} className='mfafp_card'>
           
           
            <>
            <Typography gutterBottom  bgcolor={'#E4002B'}  variant="h5" color='white' component="div" align='center'> 
            {props.details.eventTitle} 
            </Typography>
            <Typography variant="body2" color='white'  className='mfafp_chart'>
                {props.details.eventText} <br></br><br></br> 
                
            </Typography>
            <Typography variant="body2" color='white'  className='mfafp_chart'>
                {props.details.eventAddress} <br></br><br></br> 
                
            </Typography>
            <Typography variant="body2" color='white'  className='mfafp_chart'>
               Email - {props.details.eventEmail} <br></br><br></br> 
                
            </Typography>
            <Typography variant="body2" color='white'  className='mfafp_chart'>
                More info <br></br><br></br> 
                
            </Typography>
            </>
            
            
        </Card>
    </>
  )
}

export default Events;