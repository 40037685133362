import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';

 const Policy = (props) => {
    
  return (
    <Grid item>
        <Card sx={{ bgcolor: '#957b65', p:0, m:2, }} >
       
            <CardMedia
              component="img"
              alt=""
              
              image={props.details.policyImg} 
              />        
           
            
        </Card>
        </Grid>
  )
}

export default Policy;