import React from 'react'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { aboutMFA} from "/react/mfa/src/img";
import SectionTitle from '../SectionTitle';
import PageSubTitle from "../PageSubTitle";
import LinkCards from '../LinkCards';

const AboutMFA = () => {
  return (
    
    <>
      <Grid sx={{ bgcolor: '#fff'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF' }}>
          <img src={aboutMFA} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <SectionTitle tilename=''/>
            <PageSubTitle name='Missions Fellowship Australia (MFA) '/>
              <Typography variant="body1" color="" align="justify">
              MFA is a mission organisation established in 2006 with the purpose of reaching remote villages of South Asian countries with the Good News of the Lord Jesus Christ.<br></br>
MFA is registered as a religious organisation with the Australian Charity and Not-for Profits Commission (ACNC).<br></br>
We support indigenous ministry workers to reach people within their communities with the transforming gospel of grace. MFA reaches the unreached with the gospel of Jesus Christ in a culturally relevant and non-coercive way.
              </Typography>
            </Container>
          </Box>

          

          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
            <PageSubTitle name='Our Finanical Policy'/>
              <Typography variant="body1" color="" align="justify">
              100% of your donations are sent to Missions Fellowship Australia ministries. Accounts are independently audited. Donations made to Missions Fellowship Australia are not tax deductible in Australia. Donors will be given a receipt at the end of the financial year for the <b>Church Planting</b> Ministries.
              </Typography>
            </Container>
          </Box>
          
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
          <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="">
            <Grid container spacing={0} sx={{ flexGrow: 1 ,justifyContent:'center', }}> 
              
             <LinkCards details='churchplanting'/>
            </Grid>
            </Container>
          </Box>
         
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
          
        </Grid>
      </Grid>
    </>
  )
}

export default AboutMFA