import React from 'react'
import { Grid } from '@mui/material';
import { pg_srilanka, sl_promo } from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PageSubTitle from "../PageSubTitle";
import BulletPoints from '../BulletPoints';
import BarGraph from '../BarGraph';
import BarGraphPol from '../BarGraphPol';
import { PgsrilankaBulletPoints } from "/react/mfa/src/dataset/srilanka";
import { srilankaBarPoldata, srilankaBarGraphData, srilankaImgList } from "/react/mfa/src/dataset/srilanka";
import Link from '@mui/material/Link';
import ImageListApp from '../ImageListApp';

const PgSriLanka = () => {
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF' }}>
          <img src={pg_srilanka} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='About Sri Lanka'/>
                  <Typography variant="body1" color="" align="justify">
                  Today, Sri Lanka, or “venerable island”, boasts one of the fastest growing economies in the world with over 60% of the gross domestic product coming from the service sector.  However, severe income inequality and ethnic prejudice have driven large numbers of its citizens to seek work outside of the country.  As a result, these populations are exploited, abused, and at times, enslaved at the hands of unscrupulous employers.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  Ninety-nine percent of Sri Lankans say that religion is important in their lives, and the most recent civil war speaks to these strongly held beliefs. The war, waged over religious and ethnic divides, lasted from 1983 to 2009. And yet, despite efforts to preserve their religion and culture, significant increases in alcoholism, suicide, casual violence, and rape reveal a crisis in the belief systems to which Sri Lankans cling.  Buddhism, the national religion, is protected and promoted, and while freedom of religion is guaranteed by law, violence and persecution against the 8% mostly Catholic Christian minority are not uncommon.  As the number of Christians in Sri Lanka grows, we pray they continue to search for the pearl of great price - the hearts of the Sri Lankan people.
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by Religion'/>
                  <BarGraphPol {...srilankaBarPoldata}/>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/sri-lanka/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by People Group'/>
                  <Typography variant="body1" color="" align="justify">
                  <BarGraph {...srilankaBarGraphData}/>
                  </Typography>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/sri-lanka/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>
          
            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='How can you pray for Sri Lanka ?'/>
                   <BulletPoints details={PgsrilankaBulletPoints} />
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='MFA Ministries in Sri Lanka'/>
                  <Typography variant="body1" color="" align="justify">
                  <ImageListApp  imglist={srilankaImgList}/>
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Will you support one of our missions projects ?'/>
                  <Typography variant="body1" color="" align="justify">
                    <Box
                      component="img"
                      sx={{
                          maxHeight: { xs: 233, md:400 },
                            maxWidth: { xs: 350, md:500  },
                          }}
                      alt="Will you support a Child ?"
                      src={sl_promo}
                    />
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>
          
            <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PgSriLanka