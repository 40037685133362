import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { churchPlanting, villageTransformation, lifeTransformation, mercyMinistry, 
  } from "/react/mfa/src/img";
  import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
  import { useNavigate } from 'react-router-dom';

    

 function CustomCardLink (cardpgLink) {
    
}

const LinkCards = (props) => {

  const arr = [
    { id: 'churchplanting', 
      img: churchPlanting,
      path: '/churchplanting',
      title: 'Church Planting'
    },
    { id: 'villagetransformation', 
      img: villageTransformation,
      path: '/villagetransformation',
      title: 'Village Transformation'
    },
    { id: 'lifetransformation', 
      img: lifeTransformation,
      path: '/lifetransformation',
      title: 'life transformation'
    },
    { id: 'mercyministry', 
      img: mercyMinistry,
      path: '/mercyministry',
      title: 'mercy ministry'
    },
    
  ];
  const navigate = useNavigate();
  const cardBtn = arr.find(obj => {
    return obj.id === props.details;
  });

    return (
        <Grid item >
        <Card sx={{ p:0, m:2, width: 300 ,maxWidth: 335 ,minHeight: 190 , maxHeight: 200,  }}>
          <CardActionArea  onClick={() => {
                    navigate(cardBtn.path)
                    }}>
            <CardMedia
              component="img"
              height="140"
              image={cardBtn.img}
              alt=""
            />
            <CardContent alignItems='right'>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
              <Typography  variant="h6" color='' >
                {cardBtn.title}
                
              </Typography>
              <DoubleArrowRoundedIcon  color="primary" sx={{  fontSize: 40 }}/>
              </Box>
              
            </CardContent>
          </CardActionArea>
        </Card>
        </Grid>
      )
}

export default LinkCards