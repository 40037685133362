import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';



const MCMItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1f1e2c' : '#1f1e2c',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

 function MinistryCardMini(props) {
    //console.log(props);
    const navigate = useNavigate()
  return (
    <Card MCMItem sx={{ width: 290 , height: 230, backgroundColor: '#008ca8'}} className=''>
    <CardMedia
      component="img"
      alt={props.mtext}
      height = '125'
      image={props.details.pic}
    />
    <CardContent>
      <Typography gutterBottom variant="body1" component="div" color="white"> 
        {props.details.shortdesc}
      </Typography>
      
    </CardContent>
    <CardActions>
      
      <Button variant="outlined" sx={{ bgcolor: ''}} size="small"  onClick={() => {
                          navigate(props.details.path)
                        }}>Give</Button>
    </CardActions>
  </Card>
  );
}

export default MinistryCardMini