import React from 'react';
import {  homepage, mfacarousel1, mfacarousel2,mfacarousel3,mfacarousel4, mfacarouselPrayer,
   } from "C:/react/mfa/src/img";
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material';


const MFACarousel = () => {
    const images = [
        {
            id:'MFAC1homepage',
            src: homepage,
            alt: 'Alternate text for image',
            url: '/',
            caption: 'tile_ChurchPlanting'
        },
        {
            id:'MFAC2mfacarousel1',
            src: mfacarousel1,
            alt: 'Alternate text for image',
            url: '/',
            caption: 'tile_VillageTransformation'
        },
        {
            id:'MFAC3mfacarousel2',
            src: mfacarousel2,
            alt: 'Alternate text for image',
            url: '/',
            caption: 'Homepage'
        },
        {
            id:'MFAC4mfacarouselPrayer',
            src: mfacarouselPrayer,
            alt: 'Alternate text for image',
            url: '/',
            caption: 'tile_LifeTransformation'
        },
        {
            id:'MFAC5mfacarousel3',
            src: mfacarousel3,
            alt: 'Alternate text for image',
            url: '/',
            caption: 'Homepage'
        },
        {
            id:'MFAC5mfacarousel4',
            src: mfacarousel4,
            alt: 'Alternate text for image',
            url: '/',
            caption: 'Homepage'
        }
    ]

    return (
        <Carousel animation='slide' navButtonsAlwaysVisible>
            {
                images.map( (image) => <Paper key={image.id}>
                    <img src={image.src} style={{width:"100%",}} class='carouselImage'/>
                </Paper> )
            }
        </Carousel>
    )
}

export default MFACarousel;