import React from 'react';
import { teamAus } from "C:/react/mfa/src/img";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import PageSubTitle from "../PageSubTitle";

const AboutTile = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#efefef' : '#efefef',
    ...theme.typography.h4,
    padding: theme.spacing(0),
    textAlign: 'left',
    color: 'black',
  }));  
const MissionTeamAus = () => {
  return (
    <>
    <Grid sx={{ bgcolor: '#FFF'}}>
      <Grid>
        <Box sx={{ bgcolor: '#FFF' }}>
        <img src={teamAus} alt="MFA" className='mfahomebanner'/>
        </Box>
      </Grid>
      <Grid container>
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">
            <PageSubTitle name='Engaging with the Local Missions Team'/>
            <Typography variant="body1" color="" align="justify">
                By Sponsor a child, Sponsor a native missionary, Sponsor a young adult, Support Benovent Project.
            </Typography>
          </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
          <Container maxWidth="md">
                <PageSubTitle name='Connect through Prayer'/>
                <Typography variant="body1" color="" align="justify">
                Missions Fellowship Australia has always been committed to praying and seeking God. We do this as individuals and state ministry locations. One of MFA’s State level prayer initiatives is a monthly prayer gathering called the “<b>Missions Prayer Fellowship</b>”.
                <br></br><br></br>
                Each month, our overseas mission partners provide focused prayer points addressing daily ministry challenges, needs, and spiritual support. Additionally, we connect with missionaries and mission partners via Zoom to share testimonies and experiences.
              </Typography>
          </Container>
        </Box>
        
        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
                <PageSubTitle name='Annual Missions Connect Program'/>
                <Typography variant="body1" color="" align="justify">
                MFA Missions Connect is a significant part of our vision to 'Reach the unreached for a world that knows Jesus' as per Matthew 28:19
                <br></br><br></br>
                In the Missions Connect meeting, you will have the opportunity to explore what the Bible 
says about missions and evangelical ministry. You will also hear directly from missionaries 
or representatives of partner mission organizations about the work God is doing in MFA-sponsored countries and how He is advancing His church.
 So whether you tune in online or come in person, you don’t want to miss out.
                <br></br><br></br>
                Keep informed by regularly attending your state monthly Missions Prayer Fellowship meetings or by signing up for our regular communications through your local state missions team.
              </Typography>
            </Container>
        </Box>

        <Grid  container spacing={0} sx={{  height: 50}}></Grid>
        <Box  sx={{ flexGrow: 1 }}>
            <Container maxWidth="md">
                <PageSubTitle name='Contact your Local State Missions Facilitator'/>
                <Typography variant="body1" color="" align="justify">
                <b>Email:</b> missions@mfa.org.au 
                <br></br><br></br>
                If you would like to know more, we encourage you to explore further by meeting with us, or feel free to send in your questions to us via email.
                
              </Typography>
            </Container>
        </Box>
          <Grid container spacing={0} sx={{  height: 50}}></Grid>
      </Grid>
    </Grid>
  </>
  )
}

export default MissionTeamAus;