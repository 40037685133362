import React from 'react'
import { Grid } from '@mui/material';
import { pg_myanmar, my_promo } from "/react/mfa/src/img";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PageSubTitle from "../PageSubTitle";
import BulletPoints from '../BulletPoints';
import BarGraph from '../BarGraph';
import BarGraphPol from '../BarGraphPol';
import { PgmyanmarBulletPoints } from "/react/mfa/src/dataset/myanmar";
import { myanmarBarPoldata, myanmarBarGraphData, myanmarImgList } from "/react/mfa/src/dataset/myanmar";
import Link from '@mui/material/Link';
import ImageListApp from '../ImageListApp';

const PageMyanmar = () => {
  return (
    <>
      <Grid sx={{ bgcolor: '#FFF'}}>
        <Grid>
          <Box sx={{ bgcolor: '#FFF'}}>
          <img src={pg_myanmar} alt="MFA" className='mfahomebanner'/>
          </Box>
        </Grid>
        <Grid container>
          <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='About Myanmar'/>
                  <Typography variant="body1" color="" align="justify">
                  Myanmar, formerly Burma, has more than 100 ethnic groups, and is the largest country in Southeast Asia. The land is mostly covered in forests with a tropical climate and is rich in natural resources such as gems, oil, and natural gas. It shares borders with Bangladesh, India, China, Laos, and Thailand. Over 2,200 Buddhist temples, pagodas, and monasteries (built primarily between the 10th to 14th centuries) are found in the city of Bagan alone, reflecting the immense presence and influence of Buddhism in this country.
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  Myanmar is roughly 80% Buddhist, 8% Christian, and 7% Muslim. Approximately 46,000,000 remain unreached with the Gospel, which is 84% of the population! Freedom of religion is restricted, and any group the government considers a threat, including Christians, is harassed, persecuted, and often imprisoned. Buddhism, though no longer considered the state religion, is actively promoted by the military regime. Although the military has attempted to remove Christianity from the nation, the Church continues to grow. Foreign missionaries have been prohibited from working in the nation, but this has not stopped indigenous believers from sharing their faith. It is even estimated that thousands of Buddhist monks have become followers of Jesus!
                  </Typography>
                  
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by Religion'/>
                  <BarGraphPol {...myanmarBarPoldata}/>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/myanmar-burma/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Statistical population by People Group'/>
                  <Typography variant="body1" color="" align="justify">
                  <BarGraph {...myanmarBarGraphData}/>
                  </Typography>
                  <Typography variant="body2" color="" align="right">
                    Data source: 
                    <b><Link to="route" target="_blank" rel="noopener noreferrer" 
                      href="https://prayercast.com/prayer-topic/myanmar-burma/" sx={{ color: 'inherit'}}
                      >Prayer Cast</Link></b>
                  </Typography>
                </Container>
            </Box>
          
            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='How can you pray for Myanmar ?'/>
                  
                   <BulletPoints details={PgmyanmarBulletPoints} />
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='MFA Ministries in Myanmar'/>
                  <Typography variant="body1" color="" align="justify">
                    <ImageListApp  imglist={myanmarImgList}/>
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>

            <Grid  container spacing={0} sx={{  height: 50}}></Grid>
            <Box  sx={{ flexGrow: 1 }}>
                <Container maxWidth="md">
                  <PageSubTitle name='Will you support one of our missions projects ?'/>
                  <Typography variant="body1" color="" align="justify">
                    <Box
                      component="img"
                      sx={{
                          maxHeight: { xs: 233, md:400 },
                            maxWidth: { xs: 350, md:500  },
                          }}
                      alt="Will you support a Child ?"
                      src={my_promo}
                    />
                  </Typography>
                  <br></br>
                  <Typography variant="body1" color="" align="justify">
                  
                  </Typography>
                </Container>
            </Box>
          
            <Grid container spacing={0} sx={{  height: 50}}></Grid>
        </Grid>
      </Grid>
    </>
  )
}


export default PageMyanmar